.sticky{
  position: absolute;
  width: 80vw;
  height: 100%;
  z-index: 100;
  /* margin-top: -150px; */
}

.bubbles{
    position:relative;
    width:100%;
    height: 100%;
    z-index:0;
    overflow:hidden;
    top:0;
    left:0;
  }
  .bubble{
    position: absolute;
    bottom:-100px;
    width:25px;
    height:25px;
    background:#FFB8A2;
    border-radius:50%;
    opacity:0.5;
    animation: rise 1s infinite ease-in;
  }
  .bubble:nth-child(1){
    width:25px;
    height:25px;
    top:5%;
    left:10%;
    animation-duration:8s;
  }
  .bubble:nth-child(2){
    width:20px;
    height:20px;
    top:10%;
    left:35%;
    animation-duration:5s;
    animation-delay:1s;
  }
  .bubble:nth-child(3){
    width:25px;
    height:25px;
    top:5%;
    left:65%;
    animation-duration:7s;
    animation-delay:2s;
  }
  .bubble:nth-child(4){
    width:25px;
    height:25px;
    top: 10%;
    left:80%;
    animation-duration:11s;
    animation-delay:0s;
  }
  .bubble:nth-child(5){
    width:25px;
    height:25px;
    top: 10%;
    left:95%;
    animation-duration:6s;
    animation-delay:1s;
  }
  .bubble:nth-child(6){
    width:25px;
    height:25px;
    top:25%;
    left:97%;
    animation-duration:8s;
    animation-delay:3s;
  }
  .bubble:nth-child(7){
    width:25px;
    height:25px;
    top:50%;
    left:95%;
    animation-duration:12s;
    animation-delay:2s;
  }
  .bubble:nth-child(8){
    width:25px;
    height:25px;
    top:70%;
    left:92%;
    animation-duration:6s;
    animation-delay:2s;
  }
  .bubble:nth-child(9){
    width:15px;
    height:15px;
    top: 90%;
    left:85%;
    animation-duration:5s;
    animation-delay:1s;
  }
  .bubble:nth-child(10){
    width:25px;
    height:25px;
    top:95%;
    left:65%;
    animation-duration:10s;
    animation-delay:4s;
  }
  .bubble:nth-child(11){
    width:25px;
    height:25px;
    top:92%;
    left:45%;
    animation-duration:10s;
    animation-delay:4s;
  }
  .bubble:nth-child(12){
    width:25px;
    height:25px;
    top:97%;
    left:25%;
    animation-duration:10s;
    animation-delay:4s;
  }
  .bubble:nth-child(13){
    width:25px;
    height:25px;
    top:95%;
    left:10%;
    animation-duration:10s;
    animation-delay:4s;
  }
  .bubble:nth-child(14){
    width:25px;
    height:25px;
    top:65%;
    left:1%;
    animation-duration:10s;
    animation-delay:4s;
  }
  .bubble:nth-child(15){
    width:25px;
    height:25px;
    top:25%;
    left:5%;
    animation-duration:10s;
    animation-delay:4s;
  }
  @keyframes rise{
    0%{
      bottom:50px;
      transform:translateX(0);
    }
    50%{
      transform:translate(20px);
    }
    100%{
      bottom:50px;
      transform:translateX(0px);
    }
  }