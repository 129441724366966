	/*
  	Flaticon icon font: Flaticon
  	Creation date: 14/01/2017 08:23
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon.eot");
  src: url("../fonts/flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon.woff") format("woff"),
       url("../fonts/flaticon.ttf") format("truetype"),
       url("../fonts/flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
 	font-family: Flaticon;
	font-style: normal;
}

.flaticon-24-hours:before { content: "\f100"; }
.flaticon-24-hours-1:before { content: "\f101"; }
.flaticon-24-hours-sign:before { content: "\f102"; }
.flaticon-android-logo:before { content: "\f103"; }
.flaticon-apple:before { content: "\f104"; }
.flaticon-arrow:before { content: "\f105"; }
.flaticon-arrows:before { content: "\f106"; }
.flaticon-arrows-1:before { content: "\f107"; }
.flaticon-arrows-2:before { content: "\f108"; }
.flaticon-arrows-3:before { content: "\f109"; }
.flaticon-arrows-4:before { content: "\f10a"; }
.flaticon-arrows-5:before { content: "\f10b"; }
.flaticon-arrows-6:before { content: "\f10c"; }
.flaticon-arrows-7:before { content: "\f10d"; }
.flaticon-arrows-8:before { content: "\f10e"; }
.flaticon-arrows-9:before { content: "\f10f"; }
.flaticon-atom-symbol:before { content: "\f110"; }
.flaticon-avatar:before { content: "\f111"; }
.flaticon-back:before { content: "\f112"; }
.flaticon-badge-with-ribbons:before { content: "\f113"; }
.flaticon-bag:before { content: "\f114"; }
.flaticon-battery:before { content: "\f115"; }
.flaticon-boat-lifesaver:before { content: "\f116"; }
.flaticon-business:before { content: "\f117"; }
.flaticon-business-1:before { content: "\f118"; }
.flaticon-calendar:before { content: "\f119"; }
.flaticon-calendar-1:before { content: "\f11a"; }
.flaticon-cancel:before { content: "\f11b"; }
.flaticon-car:before { content: "\f11c"; }
.flaticon-car-1:before { content: "\f11d"; }
.flaticon-cell-phone:before { content: "\f11e"; }
.flaticon-chat:before { content: "\f11f"; }
.flaticon-check:before { content: "\f120"; }
.flaticon-check-symbol:before { content: "\f121"; }
.flaticon-checked:before { content: "\f122"; }
.flaticon-circular-arrow:before { content: "\f123"; }
.flaticon-clip:before { content: "\f124"; }
.flaticon-clock:before { content: "\f125"; }
.flaticon-clock-1:before { content: "\f126"; }
.flaticon-clock-2:before { content: "\f127"; }
.flaticon-clock-3:before { content: "\f128"; }
.flaticon-close:before { content: "\f129"; }
.flaticon-commenting:before { content: "\f12a"; }
.flaticon-comments:before { content: "\f12b"; }
.flaticon-communication:before { content: "\f12c"; }
.flaticon-communication-1:before { content: "\f12d"; }
.flaticon-communication-2:before { content: "\f12e"; }
.flaticon-computer:before { content: "\f12f"; }
.flaticon-danger:before { content: "\f130"; }
.flaticon-danger-1:before { content: "\f131"; }
.flaticon-down-arrow:before { content: "\f132"; }
.flaticon-download:before { content: "\f133"; }
.flaticon-download-arrow:before { content: "\f134"; }
.flaticon-drop:before { content: "\f135"; }
.flaticon-earth:before { content: "\f136"; }
.flaticon-edit:before { content: "\f137"; }
.flaticon-edit-tools:before { content: "\f138"; }
.flaticon-empty-email:before { content: "\f139"; }
.flaticon-envelope:before { content: "\f13a"; }
.flaticon-envelope-1:before { content: "\f13b"; }
.flaticon-envelope-2:before { content: "\f13c"; }
.flaticon-envelope-3:before { content: "\f13d"; }
.flaticon-eye:before { content: "\f13e"; }
.flaticon-favorite:before { content: "\f13f"; }
.flaticon-favorite-1:before { content: "\f140"; }
.flaticon-favorite-2:before { content: "\f141"; }
.flaticon-gallery:before { content: "\f142"; }
.flaticon-garbage:before { content: "\f143"; }
.flaticon-go-back-arrow:before { content: "\f144"; }
.flaticon-graphic:before { content: "\f145"; }
.flaticon-hands:before { content: "\f146"; }
.flaticon-headphones:before { content: "\f147"; }
.flaticon-heart:before { content: "\f148"; }
.flaticon-home:before { content: "\f149"; }
.flaticon-house:before { content: "\f14a"; }
.flaticon-house-outline:before { content: "\f14b"; }
.flaticon-interface:before { content: "\f14c"; }
.flaticon-interface-1:before { content: "\f14d"; }
.flaticon-layout:before { content: "\f14e"; }
.flaticon-left-arrow:before { content: "\f14f"; }
.flaticon-left-quote:before { content: "\f150"; }
.flaticon-light-bulb:before { content: "\f151"; }
.flaticon-light-bulb-1:before { content: "\f152"; }
.flaticon-like:before { content: "\f153"; }
.flaticon-like-1:before { content: "\f154"; }
.flaticon-like-2:before { content: "\f155"; }
.flaticon-link:before { content: "\f156"; }
.flaticon-list:before { content: "\f157"; }
.flaticon-list-1:before { content: "\f158"; }
.flaticon-login:before { content: "\f159"; }
.flaticon-login-1:before { content: "\f15a"; }
.flaticon-magnifier-tool:before { content: "\f15b"; }
.flaticon-magnifying-glass:before { content: "\f15c"; }
.flaticon-magnifying-glass-1:before { content: "\f15d"; }
.flaticon-magnifying-glass-browser:before { content: "\f15e"; }
.flaticon-map-marker:before { content: "\f15f"; }
.flaticon-map-marker-1:before { content: "\f160"; }
.flaticon-medal:before { content: "\f161"; }
.flaticon-medal-1:before { content: "\f162"; }
.flaticon-menu:before { content: "\f163"; }
.flaticon-menu-1:before { content: "\f164"; }
.flaticon-menu-2:before { content: "\f165"; }
.flaticon-menu-button:before { content: "\f166"; }
.flaticon-menu-options:before { content: "\f167"; }
.flaticon-minus:before { content: "\f168"; }
.flaticon-money-bag:before { content: "\f169"; }
.flaticon-monitor:before { content: "\f16a"; }
.flaticon-monitor-1:before { content: "\f16b"; }
.flaticon-music:before { content: "\f16c"; }
.flaticon-musical-note:before { content: "\f16d"; }
.flaticon-next:before { content: "\f16e"; }
.flaticon-next-1:before { content: "\f16f"; }
.flaticon-note:before { content: "\f170"; }
.flaticon-open-envelope:before { content: "\f171"; }
.flaticon-open-lock:before { content: "\f172"; }
.flaticon-padlock:before { content: "\f173"; }
.flaticon-padlock-1:before { content: "\f174"; }
.flaticon-paper-plane:before { content: "\f175"; }
.flaticon-paper-plane-1:before { content: "\f176"; }
.flaticon-paper-plane-2:before { content: "\f177"; }
.flaticon-pdf:before { content: "\f178"; }
.flaticon-pdf-file-format-symbol:before { content: "\f179"; }
.flaticon-phone-call:before { content: "\f17a"; }
.flaticon-pie-chart:before { content: "\f17b"; }
.flaticon-piggy-bank:before { content: "\f17c"; }
.flaticon-piggy-bank-1:before { content: "\f17d"; }
.flaticon-pin:before { content: "\f17e"; }
.flaticon-pin-1:before { content: "\f17f"; }
.flaticon-placeholder:before { content: "\f180"; }
.flaticon-planet-earth:before { content: "\f181"; }
.flaticon-play-button:before { content: "\f182"; }
.flaticon-play-button-1:before { content: "\f183"; }
.flaticon-play-button-2:before { content: "\f184"; }
.flaticon-plus:before { content: "\f185"; }
.flaticon-plus-symbol:before { content: "\f186"; }
.flaticon-power:before { content: "\f187"; }
.flaticon-previous:before { content: "\f188"; }
.flaticon-refresh:before { content: "\f189"; }
.flaticon-remove:before { content: "\f18a"; }
.flaticon-resizing:before { content: "\f18b"; }
.flaticon-right-arrow:before { content: "\f18c"; }
.flaticon-right-arrow-1:before { content: "\f18d"; }
.flaticon-right-arrow-2:before { content: "\f18e"; }
.flaticon-right-quotation-sign:before { content: "\f18f"; }
.flaticon-rocket:before { content: "\f190"; }
.flaticon-rocket-ship:before { content: "\f191"; }
.flaticon-screen:before { content: "\f192"; }
.flaticon-search:before { content: "\f193"; }
.flaticon-search-1:before { content: "\f194"; }
.flaticon-send-message-button:before { content: "\f195"; }
.flaticon-settings:before { content: "\f196"; }
.flaticon-settings-1:before { content: "\f197"; }
.flaticon-settings-2:before { content: "\f198"; }
.flaticon-shape:before { content: "\f199"; }
.flaticon-share:before { content: "\f19a"; }
.flaticon-share-symbol:before { content: "\f19b"; }
.flaticon-shopping-bag:before { content: "\f19c"; }
.flaticon-shopping-cart:before { content: "\f19d"; }
.flaticon-shopping-cart-1:before { content: "\f19e"; }
.flaticon-sign-in:before { content: "\f19f"; }
.flaticon-signs:before { content: "\f1a0"; }
.flaticon-size-square:before { content: "\f1a1"; }
.flaticon-smartphone:before { content: "\f1a2"; }
.flaticon-social:before { content: "\f1a3"; }
.flaticon-social-1:before { content: "\f1a4"; }
.flaticon-social-2:before { content: "\f1a5"; }
.flaticon-speaker:before { content: "\f1a6"; }
.flaticon-speech-bubble:before { content: "\f1a7"; }
.flaticon-speech-bubble-1:before { content: "\f1a8"; }
.flaticon-star:before { content: "\f1a9"; }
.flaticon-star-1:before { content: "\f1aa"; }
.flaticon-star-2:before { content: "\f1ab"; }
.flaticon-star-3:before { content: "\f1ac"; }
.flaticon-starred:before { content: "\f1ad"; }
.flaticon-tag-black-shape:before { content: "\f1ae"; }
.flaticon-technology:before { content: "\f1af"; }
.flaticon-technology-1:before { content: "\f1b0"; }
.flaticon-telephone:before { content: "\f1b1"; }
.flaticon-telephone-handle-silhouette:before { content: "\f1b2"; }
.flaticon-thumbs-up:before { content: "\f1b3"; }
.flaticon-time:before { content: "\f1b4"; }
.flaticon-timer:before { content: "\f1b5"; }
.flaticon-travel:before { content: "\f1b6"; }
.flaticon-unchecked:before { content: "\f1b7"; }
.flaticon-unlink:before { content: "\f1b8"; }
.flaticon-upload:before { content: "\f1b9"; }
.flaticon-user:before { content: "\f1ba"; }
.flaticon-user-1:before { content: "\f1bb"; }
.flaticon-user-2:before { content: "\f1bc"; }
.flaticon-user-3:before { content: "\f1bd"; }
.flaticon-user-shape:before { content: "\f1be"; }
.flaticon-users:before { content: "\f1bf"; }
.flaticon-video-player:before { content: "\f1c0"; }
.flaticon-warning:before { content: "\f1c1"; }
.flaticon-wifi:before { content: "\f1c2"; }
.flaticon-wifi-connection-signal-symbol:before { content: "\f1c3"; }