/* Zagreb Car Hire HTML Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Header Style Two / Header Style Three
5. Main Slider
6. Services Section One / Services Section Two / 
7. Single Item Carousel
8. Testimonial Carousel / Style Two / 
9. News Section / News Block / News Block Two / News Block Three
10. Map Section
11. Footer / Footer Style Two
12. Submit Form
13. Cars Block / 
14. Sponsers Carousel 
15. App Section
16. Page Title
17. Car Block 
18. Search Car Tyle Form
19. Video Box
20. Faq Section
21. Question Form Section
22. Error Section
23. MixitUp Gallery
24. Masonry Gallery
25. Blog Grid Section / Blog List Section
26. Categroies Widget
27. Search Widget
28. Contact Map
29. Contact Form

**********************************************/

@import url("https://fonts.googleapis.com/css?family=Catamaran:100,200,300,400,500,600,700,800,900");
/*font-family: 'Catamaran', sans-serif;*/

@import url("font-awesome.css");
@import url("flaticon.css");
@import url("animate.css");
@import url("owl.css");
@import url("jquery.fancybox.css");
@import url("jquery.mCustomScrollbar.min.css");
@import url("fontawesome-all.css");
@import url("custom-animate.css");
@import url("stroke-gap.css");
@import url("pe-icon-7-stroke.css");
@import url("jquery-ui.css");
@import url("jquery.fancybox.min.css");
@import url("scrollbar.css");
@import url("hover.css");
@import url("botstrap-select.min.css");
@import url("swiper.min.css");
@import url("rtl.css");
@import url("elpath.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;800;900&display=swap");

/*** 

====================================================================
	Reset
====================================================================

 ***/

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
  /* font-family: "Catamaran", sans-serif; */
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #555555;
  line-height: 1.7em;
  font-weight: 400;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #fa6f1c;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-family: "Catamaran", sans-serif;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.6em;
}

input,
button,
select,
textarea {
  font-family: "Catamaran", sans-serif;
}

p {
  position: relative;
  line-height: 1.8em;
}

.strike-through {
  text-decoration: line-through;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.content-container {
  padding-left: 0px;
}

.medium-container {
  max-width: 850px;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.theme-btn {
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.centered {
  text-align: center;
}

/*** 

====================================================================
	Btn Style One
====================================================================

 ***/

.btn-style-one {
  position: relative;
  padding: 14px 43px;
  line-height: 24px;
  text-transform: uppercase;
  background: none;
  color: #ffffff !important;
  border: 2px solid #ff8201;
  font-size: 14px;
  font-weight: 800;
  border-radius: 50px;
  background-color: #ff8201;
}

.btn-style-one:hover {
  background: none;
  color: #ff8201 !important;
  border-color: #ff8201;
}

/*** 

====================================================================
	Btn Style Two
====================================================================

 ***/

.btn-style-two {
  position: relative;
  padding: 14px 43px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff !important;
  border: 2px solid #ffffff;
  font-size: 14px;
  font-weight: 800;
  border-radius: 50px;
}

.btn-style-two:hover {
  color: #ffffff !important;
  border-color: #ff8201;
  background-color: #ff8201;
}

/*** 

====================================================================
	Btn Style Three
====================================================================

 ***/

.btn-style-three {
  position: relative;
  padding: 14px 43px;
  line-height: 24px;
  text-transform: uppercase;
  color: #fb561e;
  border: 2px solid #fb561e;
  font-size: 14px;
  font-weight: 800;
  border-radius: 50px;
  background: none;
}

.btn-style-three:hover {
  color: #ffffff !important;
  border-color: #fb561e;
  background-color: #fb561e;
}

.theme-btn .icon-left {
  padding-right: 10px;
}

.theme-btn .icon-right {
  padding-left: 10px;
}

.theme_color {
  color: #ff8201;
}

.light-font {
  font-weight: 300;
}

.regular-font {
  font-weight: 400;
}

.semibold-font {
  font-weight: 600;
}

.bold-font {
  font-weight: 700;
}

.ex-bold-font {
  font-weight: 800;
}

.heavy-font {
  font-weight: 900;
}

.bg-lightgrey {
  background-color: #f7f7f7 !important;
}

.no-bg {
  background: none !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../images/icons/preloader.GIF);
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

/*** 

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  color: #ffffff;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 38px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;
  background: #0a0a0a;
  display: none;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  color: #ffffff;
  background: #fa6f1c;
}

/*** 

====================================================================
	Main Header style
====================================================================

***/

.main-header {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  background: #222222;
}

.main-header .header-top {
  position: relative;
  background: #222222;
  color: #ffffff;
  padding: 12px 0px;
}

.main-header.header-style-one .main-box .auto-container {
  max-width: 1280px;
}

.main-header .header-top .top-left {
  position: relative;
  float: left;
}

.main-header .header-top .top-left ul li {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding-left: 25px;
  margin-right: 40px;
  color: #ffffff;
}

.main-header .header-top .top-left ul li .icon {
  position: absolute;
  left: 0px;
  top: 1px;
  font-size: 14px;
  line-height: 24px;
}

.main-header .header-top .top-left ul li a:hover {
  color: #fff;
}

.main-header .header-top .top-left ul li a {
  position: relative;
  color: #ffffff;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header .header-top .top-left ul li a:hover {
  color: #fa6f1c;
}

.main-header .header-top .top-right {
  position: relative;
  float: right;
}

.main-header .header-top .top-right ul li {
  position: relative;
  display: inline-block;
  margin-left: 50px;
}

.main-header .header-top .top-right ul li .icon {
  margin-right: 15px;
  position: relative;
  top: 1px;
}

.header-top .social-icon {
  position: relative;
}

.header-top .social-icon a {
  position: relative;
  display: inline-block;
  text-align: center;
  margin-right: 20px;
  line-height: 24px;
  font-size: 13px;
  color: #ffffff;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.header-top .social-icon a:hover {
  color: #ff8201;
}

.main-header .main-box {
  position: relative;
  padding: 0px 0px;
  z-index: 1;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.header-style-one .main-box {
  height: 50px;
}

.main-header.fixed-header .main-box {
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  margin-top: 0px !important;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 0px;
  z-index: 999;
  opacity: 1;
  height: auto;
  visibility: visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.main-header.fixed-header .main-box .outer-container {
  padding: 0px;
}
.main-header .main-box .outer-container {
  position: relative;
  padding: 0px 50px;
  border-radius: 50px;
  background-color: #ffffff;
}

.main-header .main-box .logo-box {
  position: relative;
  float: left;
  left: 0px;
  padding: 10px 0px;
  z-index: 10;
}

.fixed-header .main-box .logo-box {
  padding: 5px 0px;
}

.main-header .main-box .logo-box .logo img {
  display: inline-block;
  max-width: 100%;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header .nav-toggler {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -20px;
  display: none;
}

.main-header .nav-toggler button {
  position: relative;
  display: block;
  height: 40px;
  width: 44px;
  background: #fa6f1c;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 34px;
  border: 1px solid #fa6f1c;
  border-radius: 2px;
  font-weight: normal;
}

.main-header .nav-outer {
  position: relative;
  float: right;
}

.main-menu {
  position: relative;
  font-family: "Catamaran", sans-serif;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navigation {
  position: relative;
  margin: 0px;
}

.main-menu .navigation > li {
  position: relative;
  display: inline-block;
  padding: 19px 0px;
  margin-left: 30px;
}

.fixed-header .main-menu .navigation > li {
  padding: 14px 0px;
}

.main-menu .navigation > li > a {
  position: relative;
  display: block;
  padding: 16px 0px;
  color: #777777;
  text-align: center;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-weight: 700;
  opacity: 1;
  font-size: 14px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.header-style-three .main-box .main-menu .navigation > li > a,
.header-style-three.fixed-header .main-box .main-menu .navigation > li > a {
  color: #ffffff;
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a,
.main-menu .navigation > li.current-menu-item > a {
  color: #ff8201;
  opacity: 1;
}

.main-menu .navigation > li > ul {
  position: absolute;
  left: 0px;
  top: 120%;
  width: 220px;
  padding: 0px;
  z-index: 100;
  display: none;
  background: #ffffff;
  border-top: 3px solid #ff8201;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation > li > ul > li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.main-menu .navigation > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  line-height: 24px;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #272727;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li:hover > a {
  color: #ffffff;
  background: #fa6f1c;
}

.main-menu .navigation > li > ul > li.dropdown > a:after {
  font-family: "FontAwesome";
  content: "\f105";
  position: absolute;
  right: 10px;
  top: 10px;
  width: 10px;
  height: 20px;
  display: block;
  color: #253d4a;
  line-height: 20px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  z-index: 5;
}

.main-menu .navigation > li > ul > li.dropdown:hover > a:after {
  color: #ffffff;
}

.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  left: 100%;
  top: 20px;
  width: 220px;
  padding: 0px;
  z-index: 100;
  display: none;
  background: #ffffff;
  border-top: 3px solid #ff8201;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation > li > ul > li > ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  line-height: 24px;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #272727;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li:hover > a {
  color: #ffffff;
  background: #fa6f1c;
}

.main-menu .navigation > li > ul > li > ul > li.dropdown > a:after {
  font-family: "FontAwesome";
  content: "\f105";
  position: absolute;
  right: 10px;
  top: 11px;
  width: 10px;
  height: 20px;
  display: block;
  color: #272727;
  line-height: 20px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  z-index: 5;
}

.main-menu .navigation > li > ul > li > ul > li.dropdown:hover > a:after {
  color: #ffffff;
}

.main-menu .navigation > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.main-menu .navigation li > ul > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 0;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 34px;
  height: 30px;
  border: 1px solid #ffffff;
  background: url(../images/icons/submenu-icon.png) center center no-repeat;
  background-size: 20px;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.header-style-two .main-box {
  top: auto;
  margin-top: 0px;
  background-color: #ffffff;
}

.header-style-two .main-box .outer-container {
  padding: 0px;
}

/*Header Style Three*/

.header-style-three {
  position: absolute;
  background: none;
}

.header-style-three .main-box {
  position: relative;
  top: auto;
  margin-top: 0px;
  background-color: rgba(0, 0, 0, 0.2);
}

.header-style-three.fixed-header .main-box {
  background-color: rgba(0, 0, 0, 0.8);
  border: 0px;
}

.header-style-three .main-box .outer-container {
  padding: 0px;
  width: 100%;
  background: none;
}

/***

====================================================================
	Hidden Sidebar style
====================================================================

***/

.hidden-bar {
  position: fixed;
  top: 0;
  width: 305px;
  height: 100%;
  z-index: 999;
  background: #272727;
  transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
}

.hidden-bar .mCSB_inside > .mCSB_container {
  margin-right: 0px;
}

.hidden-bar.right-align {
  right: -400px;
}

.hidden-bar.right-align.visible-sidebar {
  right: 0px;
}

.hidden-bar.right-align .hidden-bar-closer {
  left: 0px;
}

.hidden-bar.left-align {
  left: -400px;
}

.hidden-bar.left-align.visible-sidebar {
  left: 0px;
}

.hidden-bar.left-align .hidden-bar-closer {
  right: -20px;
}

.hidden-bar .hidden-bar-closer {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  color: #272727;
  border-radius: 2px;
  text-align: center;
  line-height: 38px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  z-index: 999999;
}

.hidden-bar .hidden-bar-closer:hover {
  background: #fa6f1c;
}

.hidden-bar .hidden-bar-closer:hover .btn {
  color: #ffffff;
}

.hidden-bar .hidden-bar-closer .btn {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
}

.hidden-bar .social-icons {
  text-align: center;
  margin: 50px 0px 30px;
}

.hidden-bar .social-icons ul {
  font-size: 0;
  margin-left: -5px;
  margin-right: -5px;
}

.hidden-bar .social-icons ul li {
  display: inline-block;
  padding: 0 5px;
}

.hidden-bar .social-icons ul li a {
  display: block;
  width: 36px;
  height: 36px;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  background: #fa6f1c;
  color: #ffffff;
  border-radius: 5px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.hidden-bar .social-icons ul li a:hover {
  background: #ffffff;
  color: #fa6f1c;
}

.hidden-bar-wrapper {
  height: 100%;
}

.hidden-bar .logo {
  padding: 30px 0px;
  background: #272727;
}

.hidden-bar .logo img {
  display: inline-block;
  max-width: 100%;
}

.hidden-bar .side-menu {
  background-color: transparent;
  padding: 0;
  font-size: 13px;
  letter-spacing: 1px;
}

.hidden-bar .side-menu ul li ul a {
  background: transparent;
}

.hidden-bar .side-menu ul li ul li ul li a {
  background: transparent;
}

.hidden-bar .side-menu a.current {
  color: #fff;
}

.hidden-bar .side-menu li.current > a {
  color: #fff;
}

.hidden-bar .side-menu ul li a {
  background: transparent;
  color: #ffffff;
  display: block;
  font-weight: 500;
  text-transform: uppercase;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 15px 10px 20px;
  position: relative;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.hidden-bar .side-menu ul li a:hover,
.hidden-bar .side-menu ul > li.current > a,
.hidden-bar .side-menu ul > li > ul > li.current > a {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.hidden-bar .side-menu ul li a .btn.expander {
  background: none;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 0px;
  position: absolute;
  top: 6px;
  right: 20px;
  outline: none;
}

/*** 

====================================================================
	Fancy Box
====================================================================

***/

.fancybox-next span,
.fancybox-prev span {
  background-image: none !important;
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  text-align: center;
}

.fancybox-next span:before,
.fancybox-prev span:before {
  content: "";
  position: absolute;
  font-family: "FontAwesome";
  left: 0px;
  top: 0px;
  font-size: 12px;
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  background-color: rgba(28, 28, 28, 0.4) !important;
  color: #ffffff;
  visibility: visible;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.fancybox-next span:before {
  content: "\f178";
}

.fancybox-prev span:before {
  content: "\f177";
}

.fancybox-next:hover span:before,
.fancybox-prev:hover span:before {
  background-color: #ffffff !important;
  color: #000000;
}

.fancybox-type-image .fancybox-close {
  right: 0px;
  top: 0px;
  width: 45px;
  height: 45px;
  background: url(../images/icons/icon-cross.png) center center no-repeat;
  background-color: rgba(17, 17, 17, 0.5) !important;
}

.fancybox-type-image .fancybox-close:hover {
  background-color: #000000 !important;
}

.fancybox-type-image .fancybox-skin {
  padding: 0px !important;
}

/*** 

====================================================================
	Main Slider style
====================================================================

***/

.main-slider {
  position: relative;
  z-index: 10;
}

/*.main-slider .tp-dottedoverlay{
    background-color:rgba(0,0,0,0.70);
}*/

.main-slider .tp-caption {
  z-index: 5 !important;
}

.main-slider .text {
  position: relative;
  font-weight: 400;
  line-height: 1.4em;
  color: #ffffff;
  font-size: 16px;
  z-index: 9;
}

.main-slider h2 {
  position: relative;
  font-size: 44px;
  color: #ffffff;
  font-weight: 900;
  line-height: 1.3em;
  letter-spacing: 1px;
  z-index: 9;
  text-transform: uppercase;
}

.main-slider .btn-style-one,
.main-slider .btn-style-two {
  border-width: 2px !important;
}

.main-slider .tp-bullets.preview3 .bullet {
  background: none !important;
  width: 10px !important;
  height: 10px !important;
  border: 2px solid #ffffff !important;
  border-radius: 50% !important;
  margin: 0px 7px;
}

.main-slider .tp-bullets.preview3 .bullet:hover,
.main-slider .tp-bullets.preview3 .bullet.selected {
  background: #ff8201 !important;
}

.main-slider .tparrows.preview3,
.main-slider .tparrows.preview3 {
  background: none !important;
  width: 54px !important;
  overflow: hidden;
  margin-top: 0px !important;
  height: 54px !important;
}

.main-slider .tparrows.preview3::after {
  top: 0px;
  background: rgba(0, 0, 0, 0);
  color: #ffffff;
  font-size: 22px;
  height: 54px !important;
  line-height: 48px;
  width: 50px !important;
  border: 2px solid #ffffff;
  padding: 0px;
  border-radius: 2px;
}

.main-slider .tparrows.tp-leftarrow.preview3::after {
  content: "\f104";
  font-family: "FontAwesome";
}

.main-slider .tparrows.tp-rightarrow.preview3::after {
  content: "\f105";
  font-family: "FontAwesome";
}

.main-slider .tparrows.preview3.tp-leftarrow {
  left: 30px !important;
}

.main-slider .tparrows.preview3.tp-rightarrow {
  right: 30px !important;
}

.main-slider .tparrows.preview3:hover::after {
  background: #fa6f1c;
  border-color: #fa6f1c;
}

.main-slider .tparrows.preview3 .tp-arr-iwrapper {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.main-slider .tp-bannertimer {
  display: none !important;
}

/*.main-slider .overlay-color{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	z-index:99;
	background-color:rgba(0,0,0,0.70);
}*/

.main-slider .overlay-none .tp-dottedoverlay {
  background-color: inherit !important;
}

.main-slider .overlay-color .tp-dottedoverlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
/*** 

====================================================================
	Section Hero
====================================================================

***/
.sec-hero {
  width: 100vw;
  display: grid;
  height: 758px;
  justify-items: center;
  align-items: center;
  grid-template-rows: 2.5fr 1.5fr;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(./images/driver.png);
}
.sec-hero-text-container-text {
  display: grid;
  grid-template-rows: 3fr 1fr;
  justify-content: center;
  align-items: center;
  /* gap: 30px; */
}
.sec-hero-heading {
  font-family: "Inter";
  display: grid;
  font-weight: 700px;
  text-align: center;
  color: #ffffff;
  height: 130px;
  font-size: 64px;
  line-height: 74px;
  justify-content: center;
}
.sec-hero-description {
  /* padding-left: 250px;
  padding-right: 250px; */
  width: 90%;
  margin: 0px auto;
  font-family: "Inter";
  font-weight: 400px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  line-height: 24.2px;
}
.sec-hero-btn-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: start;
  gap: 46px;
}
.sec-hero-btn {
  width: 291px;
  height: 59px;
  padding: 15 20;
}
@media screen and (max-width: 600px) {
  .sec-hero {
    display: grid;
    height: 326.35px;
    grid-template-rows: 2fr 3fr;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 20px; */
  }
  .sec-hero-text-container-tex {
    display: grid;
    grid-template-rows: 3fr 8fr;
    background-color: #0189e2;
  }
  .sec-hero-description {
    font-size: 10px;
    font-weight: 400px;
    line-height: 12.1px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .sec-hero-btn-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: start;
    width: 291px;
  }
  .sec-hero-btn {
    width: 291px;
    height: 45px;
  }
  .sec-hero-heading {
    display: grid;
    height: 48px;
    font-size: 24px;
    line-height: 29.05px;
    justify-items: center;
    align-items: end;
    font-weight: 700;
  }
}

/***
====================================================================
	Section Title
====================================================================

***/

.sec-title {
  position: relative;
  margin-bottom: 30px;
}

.sec-title .inner-title-box {
  position: relative;
}

.sec-title .inner-title-box h2 {
  font-size: 24px;
  font-weight: 800;
  color: #000000;
  text-transform: uppercase;
  line-height: 1.4em;
}

.sec-title .inner-title-box h2 span {
  color: #cccccc;
}
@media screen and (max-width: 600px) {
  sec-title .inner-title-box h2 {
    text-align: center;
    font-size: 20px;
  }
}

.sec-title .rotate-title {
  position: absolute;
  left: -80px;
  top: 0px;
  line-height: 1em;
  color: #f1f1f1;
  font-size: 24px;
  padding-right: 10px;
  font-weight: 900;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
@media screen and (max-width: 600px) {
  .sec-title .rotate-title {
    text-align: center;
  }
}

.sec-title .rotate-title:before {
  position: absolute;
  content: "";
  left: 100%;
  top: 50%;
  width: 1px;
  height: 40px;
  background-color: #ff8201;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/*** 

====================================================================
	About Section 
====================================================================

***/

.aboutus_container {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  place-content: center;
  width: 100vw;
  height: 478;
  position: relative;

}
.aboutus_text_container {
  display: grid;
  grid-template-columns: 1fr 4fr;
  justify-items: center;
}
.aboutus_text_slash {
  display: grid;
  grid-template-rows: 142px 44px;
  width: 48px;
  row-gap: 5px;
  justify-items: center;
  /* padding: 22px 0 0 0; */
  padding: 30px 0;
}

.aboutus_blur_heading {
  display: grid;
  justify-items: flex-start;
  height: 165px;
  font-family: "Inter";
  font-weight: 700;
  line-height: 48px;
  font-size: 32px;
  color: #f0f0f0;
  writing-mode:vertical-lr;
  /* writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg); */
}
.line_img {
  display: grid;
  justify-items: center;
  align-items: center;
  height: 94px;
}
.aboutus_texts {
  display: grid;
  grid-template-rows: 9fr 1fr;
}
.aboutus_two_texts {
  display: grid;
  grid-template-rows: 1fr 2fr;
  /* row-gap: 20px; */
  align-items: center;
  height: 478px;
}
.aboutus_heading {
  font-family: "Inter";
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
}
.text {
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 21.78px;
}

.aboutus_car_img {
  display: flex;
  align-items: center;
  /* width: auto; */
  position: relative;
}
@media screen and (max-width: 812px) {
  /* .aboutus_container {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    place-content: center;
    align-items: center;
    width: 100vw;
    height: 478;
  } */
  .aboutus_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    height: fit-content;
    width: 100vw;
    padding: 25px;
  }
  .aboutus_text_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    /* background: #e07474; */
  }
  .aboutus_text_slash {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100vw;
    column-gap: 40px;
    height: 30px;

    padding: 0px 50px;
    /* justify-content: center; */
    /* align-items: center; */

    /* background: #000; */
    /* transform: rotate(360deg); */
  }

  .aboutus_blur_heading {
    /* display: grid;
    justify-items: flex-start; */
    /* height: 165px; */
    font-family: "Inter";
    font-weight: 700;
    line-height: 30px;
    font-size: 20px;
    color: #f0f0f0;
    writing-mode: horizontal-tb;
    /* writing-mode: vertical-rl; */
    /* text-orientation: mixed; */
    transform: rotate(360deg);
    height: 30px;
  }
  .line_img {
    display: flex;
    justify-items: center;
    align-items: center;
    height: 35px;
    width: auto;
    transform: rotate(90deg);
  }

  .aboutus_car_img {
    display: none;
  }

  /* .aboutus_text_slash {
    display: flex;
    flex-direction: column;
  } */
  .aboutus_texts {
    display: flex;
    flex-direction: column;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
  }
  /* .aboutus_text_container {
      display: grid;
      grid-template-rows: 1fr ;
     /* height: 500px; *
  
    } */
  .aboutus_two_texts {
    display: flex;
    flex-direction: column;
    /* grid-template-rows: 1fr; */
    row-gap: 15px;
    width: 99vw;
    justify-content: center;
    /* height: 400px; */
    height: auto;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 20px;
  }

  .aboutus_heading {
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
  }
  .text {
    font-family: "Inter";
    font-weight: 400;
    font-size: 10px;
    line-height: 21px;
  }
  /* .aboutus_blur_heading {
    display: grid;
    justify-items: flex-start;
    height: 165px;
    font-family: "Inter";
    font-weight: 700;
    line-height: 48px;
    font-size: 32px;
    color: #f0f0f0;
    /* writing-mode: vertical-rl; */
  /* text-orientation: mixed;
    transform: rotate(180deg); *
  } */

  /* .aboutus_container {
    align-items: center;
    justify-items: center;
    height: 500px;
    width: 100vw ;
  } */
}
/* @media screen and (max-width: 812px) { */
/* .aboutus_container {
    align-items: center;
    justify-items: center;
    height: 550px;
    width: 100vw ;
  } */

/* .aboutus_text_container {
    /* display: grid;
    grid-template-rows: 1fr 2fr !important; *


  } */
/* .aboutus_texts{
    display: grid;
    grid-template-rows: 1fr;
    height: auto;
    text-align: center;
/* background-color: #0189e2; *
  } */
/* .text {
    font-family: "Inter";
    font-weight: 400;
    font-size: 10px;
    line-height: 21px;
    text-align: center;
  }
   */

/* .aboutus_blur_heading {
    display: grid;
    justify-items: flex-start;
    height: 165px;
    font-family: "Inter";
    font-weight: 700;
    line-height: 48px;
    font-size: 32px;
    color: #f0f0f0;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
  } */
/* .aboutus_texts {
    display: grid;
    grid-template-rows: 1fr;
    } *
}

/* .about-section {
  position: relative;
  background-position: right top;
  background-repeat: no-repeat;
}

.about-section .content-column {
  position: relative;
  max-width: 520px;
  padding: 10px 0px 10px;
}

.about-section .content-column .inner-box {
  position: relative;
}

.about-section .content-column .inner-box .text {
  position: relative;
}

.about-section .content-column .inner-box .text p {
  position: relative;
  font-family: "Catamaran", sans-serif;
  color: #3f3f3f;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 5px;
  line-height: 21.78px;
}

.about-section .content-column .inner-box .book-btn {
  position: relative;
} */

/* @media screen and (max-width: 600px) {
  .about-section .content-column .inner-box .book-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 195px;
    margin: 0 auto; /* Set margin to "auto" for horizontal centering 
  }
} */

/*** 

====================================================================
	Services Section One
====================================================================

***/

.services-section-one {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  padding: 50px 0px 60px;
  background-color: #fafafa;
}

.services-section-one.style-two {
  background-color: #ffffff;
}

.services-section-one .sec-title {
  margin-bottom: 40px;
}

.services-block {
  position: relative;
  margin-bottom: 40px;
  padding: 0px 30px;
}

.services-block .inner-box {
  position: relative;
  text-align: center;
}

.services-block .inner-box .icon-box {
  position: relative;
  width: 116px;
  height: 116px;
  margin: 0 auto;
  color: #6d6e70;
  font-size: 40px;
  border-radius: 50%;
  line-height: 110px;
  text-align: center;
  margin-bottom: 22px;
  border: 1px dashed #afafaf;
}

.services-block .inner-box .upper-box {
  position: relative;
  display: inline-block;
}

.services-block .inner-box .upper-box .number {
  position: absolute;
  right: 0px;
  top: 0px;
  color: #ffffff;
  font-size: 14px;
  width: 28px;
  height: 28px;
  line-height: 29px;
  font-weight: 600;
  border-radius: 50%;
  display: inline-block;
  background-color: #fb561e;
}

.services-block .inner-box .lower-box {
  position: relative;
}

.services-block .inner-box .lower-box h3 {
  position: relative;
  line-height: 1em;
  padding-bottom: 14px;
}

.services-block .inner-box .lower-box h3 a {
  position: relative;
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.services-block .inner-box .lower-box h3 a:hover {
  color: #fb561e;
}

.services-block .inner-box .lower-box h3:after {
  position: absolute;
  content: "";
  bottom: 0px;
  width: 30px;
  height: 2px;
  left: 50%;
  margin-left: -15px;
  background-color: #fb561e;
}

.services-block .inner-box .lower-box .text {
  position: relative;
  color: #777777;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  margin-top: 24px;
}

/*** 

====================================================================
	service Section
====================================================================

***/

.sec-service-main-heading {
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
  text-align: center;
  /*  height: 38px; */
  margin: auto;
}

.sec-service-card-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 60px;
  align-items: center;
  justify-content: center;
}

/* .sec-service-card-grid-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  align-items: center;
  justify-items: center;
}
@media screen and (max-width:600px){
  .sec-service-card-grid-container{
    display: grid;
    grid-template-rows: 1fr;
    column-gap: 10px;
    align-items: center;
    justify-items: center;
  }
} */

/* .sec-service-grid-card{
  display: grid;
  grid-template-rows: 1fr 2fr ;
  align-items: center;
  justify-items: center;
  border: 1px solid #959da5;
  box-shadow: 0 8px 23px 0 rgba(0, 0, 0, 0.4);
} */
.sec-service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width:364px; */
  width: 340px;
  height: 328px;
  border: 1px solid #959da5;
  gap: 36px;
  box-shadow: 0 8px 23px 0 rgba(0, 0, 0, 0.4);

  padding: 30px 4px;
  border-radius: 10px;
  /* height: {ls:'328px',xs:"252px"},
  */
}



.sec-service-img {
  height: 58px;
  width: 58px;
}
@media screen and (max-width: 600px) {
  .sec-service-img {
    height: 38.25px;
    width: 38.25px;
  }
}

/* .sec-service-text-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 13px;
} */

.sec-service-text-grid-div {
  display: grid;
  grid-template-rows: 1fr 3fr;
  align-items: center;
  justify-items: center;
  /* gap: 13px; */
}

.sec-service-heading {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: center;
  width: 270px;
  height: 22px;
  color: #121212;
}
.sec-service-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  width: 316px;
  height: 135px;
  padding: 1px 10px;
}

/*** 

====================================================================
	JoinASDriver Section
====================================================================

***/
.joinasdriver_container {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 675px;
  column-gap: 20px;
  width: 98vw;
  margin: auto;
  margin-bottom: 100px;
}
.joinasdriver_img {
  height: 675px;
}
.join-as-driver-img {
  height: 675px;
  width: 653px;
}
.joinasdriver_form_container {
  display: grid;
  grid-template-rows: 2fr 8fr;
  height: 675px;
}
.joinasdriver_heading {
  display: grid;
  grid-template-rows: 2fr 3fr;
}
.join-as-driver-heading {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
}
.join-as-driver-sub-heading {
  width: 100%;
  height: 52px;
  margin-bottom: 19px;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  /* lineHeight: { ls: "26px", xs: "16.1px" }, */
  /* height={{ ls: "52px", md: "52px", xs: "32px" }} */
  /* fontSize: { ls: "15px", md: "15px", xs: "10px" }, */
}
.joinasdriver_form {
}

.form {
  display: grid;
  grid-template-rows: repeat(7, 0.9fr);
  align-items: center;
}

.scheduledemo_form {
  display: grid;
  /* grid-template-rows: repeat(7, 0fr); */
  gap:15px;
  align-items: center;
}
.join-as-driver-upload-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* sx={{ marginBottom: { ls: "42px", md: "42px", xs: "10px" } }} */
}
.upload-text {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.upload-icon {
  height: 14px;
  width: 14px;
  margin: 0px 5px;
}
.file-choose-text {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.join-as-driver-btn {
  background-color: #fb561e !important ;
  height: 41px;
  padding: 12px 24px;
  color: #ffffff;
  /* marginTop: { ls: "16px", md: "16px", xs: "10px" }, */
  width: 100%;
}

@media screen and (max-width: 700px) {
  .joinasdriver_container {
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
  }
  .joinasdriver_img {
    display: none;
  }
}

/*** 

====================================================================
	driver services Section
====================================================================

***/

.driver_services_container {
  /* width: 1211px;
  height: 969px; */
  width: 80vw;
  /* height: 969px; */
  gap: 85px;
  /* padding: 20px; */
  /* margin-left: 114.5px; */
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  justify-items: center;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
}
.Driver_srvices_item1 {
  display: grid;
  grid-template-rows: 1fr 3fr;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  /* width: 1211px; */
  width: 80vw;
  /* height: 193px; */
  /* padding: 0px 29px 0px 0px; */
}
.Driver_srvices_items {
  display: grid;
  grid-template-columns: 1fr 6fr;
  justify-content: center;
}
.driver_services_img {
  width: auto;
  height: auto;
  justify-content: center;
  background-position: center center;
  background-repeat: no-repeat;
  /* background-image: url('../images/service_img1.png');   */
}
.driver_services_text {
  display: grid;
  grid-template-rows: 1fr 4fr;
  background: #f9e1d9;
  justify-content: center;
}
.xyz {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the entire space without stretching */

  /* object-fit: cover;  */
  /* Ensures the image fills the entire space without stretching */
}

/* text */
.driver_services_Main_heading {
  font-family: "Inter";
  font-weight: 500;
  font-size: 32px;
  line-height: 38.4px;
  text-align: center;
}
.driver_services_subheading {
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 21.6px;
  text-align: center;
  color: #373737;
}
.driver_services_heading {
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  line-height: 28.8px;
  color: #373737;
}
.driver_services_description {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: #373737;
}

/*** 

====================================================================
	schdule_demo_form
====================================================================

***/

.schduledemo_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px;
}
.schduledemo_heading {
  display: flex;
  flex-direction: column;
}
.schduledemo_form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.scheduledemo_form{
  width: 95%;
}

.scheduledemo-btn {
  background-color: #fb561e !important ;
  height: 41px;
  padding: 12px 24px;
  color: #ffffff;
  /* marginTop: { ls: "16px", md: "16px", xs: "10px" }, */
  width: 100%;
}
.scheduledemo_heading {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
}
.scheduledemo_sub_heading {
  width: 100%;
  height: 52px;
  margin-bottom: 19px;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  /* lineHeight: { ls: "26px", xs: "16.1px" }, */
  /* height={{ ls: "52px", md: "52px", xs: "32px" }} */
  /* fontSize: { ls: "15px", md: "15px", xs: "10px" }, */
}

/*** 

====================================================================
	Cars Section
====================================================================

***/

.cars-section {
  position: relative;
}

.cars-section .content-column {
  position: relative;
  padding: 140px 85px 150px 15px;
}

.cars-section .content-column .inner-box {
  position: relative;
}

.cars-section .content-column .inner-box .dark-text {
  position: relative;
  color: #232323;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
}

.cars-section .content-column .inner-box .text {
  color: #777777;
  font-size: 14px;
  margin-bottom: 35px;
}

.cars-section .carousel-column {
  position: relative;
  padding: 200px 0px 100px;
}

.cars-section .carousel-column .single-item-carousel:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: 76px;
  bottom: 90%;
  background-color: #ff8201;
}

.cars-section .carousel-column .single-item-carousel:after {
  position: absolute;
  content: "";
  left: 72px;
  bottom: 90%;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #ff8201;
}

.cars-section .carousel-column h2 {
  position: relative;
  color: #232323;
  font-size: 18px;
  font-weight: 700;
  padding-left: 98px;
  text-transform: uppercase;
}

.cars-section .carousel-column .car-item {
  position: relative;
  padding-top: 50px;
}

.cars-section .owl-controls {
  margin: 0px !important;
}

.cars-section .owl-controls .owl-dots {
  display: none !important;
}

.cars-section .owl-controls .owl-nav {
  margin: 0px !important;
  position: absolute;
  left: 0px;
  top: -32px;
}

.cars-section .owl-controls .owl-nav [class*="owl-"] {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 20px;
  padding: 0px;
  color: #cccccc;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  border-radius: 0px;
  background: none !important;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.cars-section .owl-controls .owl-nav [class*="owl-"]:hover {
  color: #404040;
}

/*** 

====================================================================
	Testimonial Section
====================================================================

***/

.testimonial-section {
  position: relative;
  padding: 110px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}

.testimonial-section:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}

.testimonial-section .sec-title .inner-title-box h2 {
  color: #ffffff;
}

.testimonial-section .sec-title .inner-title-box .rotate-title {
  color: #4f4f4f;
}

.testimonial-block {
  position: relative;
  max-width: 900px;
}

.testimonial-block .inner-box {
  position: relative;
  padding-left: 130px;
}

.testimonial-block .inner-box .quote-icon {
  position: absolute;
  left: 0px;
  top: 25px;
  color: #2d2927;
  font-size: 50px;
  line-height: 1em;
}

.testimonial-block .inner-box .text {
  color: #aaaaaa;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
}

.testimonial-block .inner-box .author-info {
  position: relative;
  padding-left: 85px;
  padding-top: 10px;
  margin-top: 30px;
  min-height: 68px;
}

.testimonial-block .inner-box .author-info .image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 68px;
  height: 68px;
  border-radius: 50%;
}

.testimonial-block .inner-box .author-info .image img {
  border-radius: 50%;
}

.testimonial-block .inner-box .author-info h3 {
  position: relative;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.testimonial-block .inner-box .author-info .designation {
  position: relative;
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}

.testimonial-block .inner-box .carousel-number {
  position: absolute;
  right: 0px;
  bottom: 0px;
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
}

.testimonial-block .inner-box .carousel-number span {
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}

.testimonial-section .owl-controls {
  margin-top: 50px;
}

.testimonial-section .owl-controls .owl-nav {
  display: none !important;
}

.testimonial-section .owl-dots .owl-dot {
  position: relative;
}

.testimonial-section .owl-dots .owl-dot span {
  margin: 0px 5px;
  width: 30px;
  height: 2px;
  border-radius: 0px;
  border: 1px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  background-color: #ffffff;
}

.testimonial-section .owl-dots .owl-dot:hover span,
.testimonial-section .owl-dots .owl-dot.active span {
  border: 1px;
  background: #ff8201;
  border-color: #ff8201;
}

.testimonial-section .owl-dots .owl-dot:before {
  position: absolute;
  content: "";
  left: 5px;
  top: -4px;
  width: 20px;
  height: 2px;
  opacity: 0;
  background-color: #ff8201;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.testimonial-section .owl-dots .owl-dot:hover::before,
.testimonial-section .owl-dots .owl-dot.active::before {
  opacity: 1;
}

/*** 

====================================================================
	News Section
====================================================================

***/

.news-section {
  position: relative;
  padding: 145px 0px 145px;
}

.news-block {
  position: relative;
}

.news-block .inner-box {
  position: relative;
}

.news-block .inner-box .image-column {
  position: relative;
}

.news-block .inner-box .image-column .image {
  position: relative;
  padding-left: 1px;
}

.news-block .inner-box .image-column img {
  position: relative;
  width: 100%;
}

.news-block .inner-box .content-column {
  position: relative;
}

.news-block .inner-box .content-column .inner {
  position: relative;
  padding: 115px 0px 100px;
}

.news-block .inner-box .content-column .inner .posted {
  position: relative;
  font-size: 14px;
  color: #777777;
  margin-bottom: 4px;
}

.news-block .inner-box .content-column .inner .posted:before {
  position: absolute;
  content: "";
  right: 102%;
  top: 10px;
  width: 60px;
  height: 1px;
  background-color: #ff8201;
}

.news-block .inner-box .content-column .inner h3 {
  position: relative;
  line-height: 1em;
}

.news-block .inner-box .content-column .inner h3 a {
  position: relative;
  color: #232323;
  font-size: 18px;
  font-weight: 700;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block .inner-box .content-column .inner h3 a:hover {
  color: #ff8201;
}

.news-block .inner-box .content-column .inner .text {
  position: relative;
  color: #777777;
  font-size: 14px;
  margin-top: 30px;
}

.news-block .inner-box .content-column .inner .view-more {
  position: relative;
  font-size: 13px;
  font-weight: 800;
  color: #ff8201;
  float: right;
  margin-top: 40px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block .inner-box .content-column .inner .view-more:hover {
  color: #232323;
}

.news-section .owl-controls {
  margin: 0px !important;
}

.news-section .owl-controls .owl-dots {
  display: none !important;
}

.news-section .owl-controls .owl-nav {
  margin: 0px !important;
  position: absolute;
  right: 0px;
  top: 0px;
  font-family: "Catamaran", sans-serif;
}

.news-section .owl-controls .owl-nav [class*="owl-"] {
  position: relative;
  display: inline-block;
  margin: 0px 0px 0px 6px;
  padding: 3px 20px;
  border: 1px solid transparent;
  color: #cccccc;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  border-radius: 50px;
  text-transform: uppercase;
  background: none !important;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  font-family: "Catamaran", sans-serif;
}

.news-section .owl-controls .owl-nav [class*="owl-"]:hover {
  color: #1c1c1c;
  border-color: #888888;
}

/*** 

====================================================================
	Main Footer
====================================================================

***/

.main-footer {
  position: relative;
}

.main-footer .footer-upper {
  position: relative;
}

.map-section {
  position: relative;
}

.main-footer .footer-upper .map-section .map-overlay {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.main-footer .footer-upper .map-title {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  text-align: center;
  padding: 120px 0px;
}

.main-footer .footer-upper .map-title h2 {
  position: relative;
  color: #ffffff;
  font-size: 24px;
  font-weight: 800;
}

.main-footer .footer-upper .map-title .icon {
  position: relative;
  color: #ffffff;
  font-weight: 400;
  font-size: 28px;
  margin-right: 10px;
}

.main-footer .footer-bottom {
  position: relative;
  padding: 35px 0px;
  background-color: #232323;
}

.main-footer .footer-bottom .copyright {
  position: relative;
  font-size: 14px;
  color: #aaaaaa;
  margin-top: 5px;
  line-height: 24px;
}


.main-footer .footer-bottom .footer-nav {
  position: relative;
}

.main-footer .footer-bottom .footer-nav li {
  position: relative;
  margin-right: 28px;
  display: inline-block;
}

.main-footer .footer-bottom .footer-nav li a {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #aaaaaa;
}

.main-footer .footer-bottom .footer-nav li a img {
  position: relative;
  top: -3px;
  display: inline-block;
}

.main-footer .footer-bottom .footer-nav li a:hover {
  color: #ffffff;
}

/*Footer Style Two*/

.footer-style-two {
  position: relative;
  background-color: #232323;
}

.footer-style-two .footer-widget {
  margin-bottom: 30px;
}

.footer-style-two .footer-widget .logo-box {
  margin-top: -15px;
}

.footer-style-two .footer-widget .text {
  position: relative;
  color: #aaaaaa;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.footer-style-two .footer-upper {
  position: relative;
  padding: 80px 0px 50px;
  background: url(../images/resource/footer-botom-layer.png) right bottom
    no-repeat;
}

.list-style {
  position: relative;
}

.list-style li {
  position: relative;
  padding-left: 55px;
  min-height: 35px;
  color: #aaaaaa;
  font-size: 14px;
  margin-bottom: 20px;
  padding-top: 7px;
  /*line-height: 35px;*/
}

.list-style li .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 35px;
  height: 35px;
  color: #e8e8e8;
  font-size: 16px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  border: 1px solid #777777;
}

.footer-style-two .footer-column h2 {
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 40px;
  padding-bottom: 13px;
  color: #ffffff;
}

.footer-style-two .footer-column h2:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 38px;
  border-bottom: 1px solid #ff8201;
}

.footer-style-two .posts-widget .post {
  position: relative;
  min-height: 68px;
  padding-left: 100px;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.footer-style-two .posts-widget .post:last-child {
  border: none;
  padding-bottom: 0px;
}

.footer-style-two .posts-widget .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 68px;
  height: 68px;
}

.footer-style-two .posts-widget .post .post-thumb .overlay-link {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(252, 114, 30, 0.7);
  color: #ffffff;
  opacity: 0;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.footer-style-two .posts-widget .post:hover .post-thumb .overlay-link {
  opacity: 1;
}

.footer-style-two .posts-widget .post .post-thumb .overlay-link span {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  width: 30px;
  margin-left: -15px;
  margin-top: -15px;
  line-height: 30px;
  font-size: 16px;
}

.footer-style-two .posts-widget .post .post-thumb img {
  display: block;
  width: 100%;
}

.footer-style-two .posts-widget .post .desc-text {
  position: relative;
  font-size: 14px;
  margin-top: -3px;
  color: #ffffff;
  line-height: 1.8em;
  margin-bottom: 0px;
}

.footer-style-two .posts-widget .post .desc-text a {
  color: #ffffff;
}

.footer-style-two .posts-widget .post .time {
  color: #9e9e9e;
  line-height: 24px;
  font-style: italic;
}

.footer-style-two .gallery-widget {
  position: relative;
}

.footer-style-two .gallery-widget .image {
  position: relative;
  float: left;
  width: 25%;
  padding: 0px 10px 8px 0px;
}

.footer-style-two .gallery-widget .image img {
  position: relative;
  display: block;
  width: 100%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.footer-style-two .gallery-widget .image img:hover {
  opacity: 0.7;
}

.booking-form {
  position: relative;
  margin-top: -145px;
  z-index: 99;
}

.booking-form .booking-form-inner {
  position: relative;
  padding: 35px 35px 10px;
  background-color: #fafafa;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
}

/*Default Form*/

.default-form {
  position: relative;
}

.default-form .form-group {
  position: relative;
  margin-bottom: 25px;
}

.default-form .form-group.no-margin {
  margin-bottom: 0px;
}

.default-form .form-group .group-inner {
  position: relative;
}

.default-form .form-group .group-inner .field-label {
  position: relative;
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 22px;
}

.default-form .form-group .group-inner .input-label {
  position: relative;
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  line-height: 52px;
  display: inline-block;
}

.default-form .form-group .group-inner input,
.default-form .form-group .group-inner select {
  position: relative;
  height: 52px;
  width: 100%;
  line-height: 32px;
  padding: 10px 20px;
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.default-form .form-group .group-inner .ui-selectmenu-button {
  position: relative;
  height: 52px;
  width: 100%;
  line-height: 32px;
  padding: 10px 20px;
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  cursor: pointer;
  background: #ffffff url(../images/icons/select-arrow.png) right top no-repeat;
}

.default-form .form-group .group-inner .ui-selectmenu-button .ui-icon {
  background-image: none !important;
}

.default-form .form-group .group-inner input:focus,
.default-form .form-group .group-inner select:focus,
.default-form .form-group .group-inner textarea:focus {
  border-color: #ff8201;
}

.default-form .form-group .group-inner .number-of-people {
  position: relative;
  width: 80px;
  height: 52px;
  float: right;
  background-color: #ffffff;
}

.default-form .form-group .group-inner select {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  cursor: pointer;
  background: #ffffff url(../images/icons/select-arrow.png) right top no-repeat;
}

.default-form .form-group .group-inner textarea {
  position: relative;
  padding: 10px 20px;
  height: 120px;
  width: 100%;
  resize: none;
  border-radius: 1px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.default-form .form-group .group-inner button {
  margin-top: 10px;
}

.default-form .form-group .select-box {
  position: relative;
  display: inline-block;
  margin-right: 40px;
  margin-top: 14px;
  margin-bottom: 12px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.default-form .form-group .select-box:last-child {
  margin-right: 0px;
}

.default-form .form-group .select-box label {
  position: relative;
  font-size: 14px;
  color: #000000;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 0px;
  padding-left: 30px;
  line-height: 29px;
}

.default-form .form-group .select-box input[type="radio"] {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
  visibility: hidden;
  border-radius: 50%;
  background-color: #ffffff;
}

.default-form .form-group .select-box .default-check {
  position: absolute;
  left: 0px;
  top: 2px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
}

.default-form .form-group .select-box .check-icon {
  position: absolute;
  content: "\f00c";
  left: 0px;
  top: 4px;
  width: 22px;
  height: 22px;
  color: #ff8201;
  z-index: 99;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  opacity: 0;
  font-family: "FontAwesome";
}

.default-form
  .form-group
  .select-box
  input[type="radio"]:checked
  + label
  .check-icon {
  opacity: 1;
}

.default-form input.error,
.default-form select.error,
.default-form textarea.error {
  border-color: #ff0000 !important;
}

.default-form label.error {
  display: block;
  line-height: 24px;
  padding: 5px 0px 0px;
  margin: 0px;
  text-transform: uppercase;
  font-size: 11px;
  color: #ff0000;
  font-weight: 500;
}

.default-form .lower-form-box {
  position: relative;
  border-top: 1px solid #e0e0e0;
  padding-top: 30px;
}

.default-form.style-two .widget-content {
  position: relative;
  padding: 30px 30px 15px;
}

/*** 

====================================================================
	Cars Select Section
====================================================================

***/

.cars-select-section {
  position: relative;
  padding: 130px 0px 140px;
}

.cars-select-section.style-two {
  position: relative;
  background-color: #fafafa;
}

.car-block {
  position: relative;
}

.car-block .inner-box {
  position: relative;
}

.car-block .inner-box h3 {
  position: relative;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.car-block .inner-box .lower-box {
  position: relative;
}

.car-block .inner-box .lower-box .text {
  position: relative;
  color: #777777;
  font-size: 14px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.car-block .inner-box .lower-box .arrow .icon {
  position: relative;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 28px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  background-color: #232323;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.car-block .inner-box .lower-box .arrow:hover .icon {
  background-color: #ff8201;
}

.car-block .inner-box .lower-box .arrow .text {
  position: relative;
  color: #ff8201;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  opacity: 0;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.car-block .inner-box .lower-box .arrow:hover .text {
  opacity: 1;
}

.cars-select-section .owl-controls {
  padding: 25px 0 0;
  position: relative;
  text-align: center;
  top: 0;
}

.cars-select-section .owl-theme .owl-controls .owl-nav [class*="owl-"] {
  color: #cfcfcf;
  font-size: 20px;
  height: 45px;
  line-height: 42px;
  padding: 0;
  position: relative;
  transition: all 500ms ease 0s;
  width: 45px;
  border: 1px solid #cfcfcf;
  border-radius: 0px;
  background: transparent;
}

.cars-select-section .owl-theme .owl-controls .owl-nav [class*="owl-"]:hover {
  background: #ffffff;
  border-color: #7f7f7f;
  color: #7f7f7f;
}

.cars-select-section .owl-dots {
  display: none !important;
}

/*** 

====================================================================
	Default Testimonials
====================================================================

***/

.default-testimonials {
  position: relative;
  padding: 140px 0px 85px;
}

.default-testimonials .client-tesimonial {
  position: relative;
  margin-bottom: 60px;
}

.default-testimonials .client-tesimonial h2 {
  position: relative;
  color: #000000;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 34px;
  text-transform: uppercase;
}

.testimonial-block-two {
  position: relative;
  max-width: 900px;
}

.testimonial-block-two .inner-box {
  position: relative;
}

.testimonial-block-two .inner-box .quote-icon {
  position: absolute;
  left: 25px;
  top: 30px;
  color: #ededed;
  font-size: 36px;
  line-height: 1em;
  z-index: 1;
}

.testimonial-block-two .inner-box .text {
  position: relative;
  color: #777777;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  background-color: #fafafa;
  padding: 30px 30px 30px 90px;
}

.testimonial-block-two .inner-box .author-info {
  position: relative;
  padding-left: 85px;
  padding-top: 10px;
  margin-top: 30px;
  min-height: 68px;
  margin-left: 25px;
}

.testimonial-block-two .inner-box .author-info .image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 68px;
  height: 68px;
  border-radius: 50%;
}

.testimonial-block-two .inner-box .text:before {
  position: absolute;
  left: 45px;
  top: 68%;
  content: "";
  width: 1px;
  height: 75%;
  z-index: 1;
  background-color: #ff8201;
}

.testimonial-block-two .inner-box .text:after {
  position: absolute;
  left: 41px;
  top: 140%;
  content: "";
  width: 10px;
  height: 10px;
  z-index: 1;
  border-radius: 50%;
  background-color: #ff8201;
}

.testimonial-block-two .inner-box .author-info .image img {
  border-radius: 50%;
}

.testimonial-block-two .inner-box .author-info h3 {
  position: relative;
  color: #232323;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}

.testimonial-block-two .inner-box .author-info .designation {
  position: relative;
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}

.testimonial-block-two .inner-box .carousel-number {
  position: absolute;
  right: 0px;
  bottom: 15px;
  font-size: 24px;
  color: #232323;
  font-weight: 700;
}

.testimonial-block-two .inner-box .carousel-number span {
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}

.client-tesimonial .owl-controls {
  display: none !important;
}

/*** 

====================================================================
	News Testimonial Column
====================================================================

***/

.default-testimonials .news-testimonial-column {
  position: relative;
  margin-bottom: 60px;
}

.default-testimonials .news-testimonial-column h2 {
  position: relative;
  color: #000000;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 34px;
  text-transform: uppercase;
}

/*News Block Two*/

.news-block-two {
  position: relative;
}

.news-block-two .inner-box {
  position: relative;
}

.news-block-two .inner-box .image-column {
  position: relative;
  margin-bottom: 20px;
}

.news-block-two .inner-box .image-column img {
  position: relative;
  width: 100%;
}

.news-block-two .inner-box .content-column {
  position: relative;
}

.news-block-two .inner-box .content-column .inner {
  position: relative;
  padding: 0px 0px 0px;
}

.news-block-two .inner-box .content-column .inner .posted {
  position: relative;
  font-size: 14px;
  color: #777777;
  margin-bottom: 4px;
}

.news-block-two .inner-box .content-column .inner h3 {
  position: relative;
  line-height: 1em;
}

.news-block-two .inner-box .content-column .inner h3 a {
  position: relative;
  color: #232323;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block-two .inner-box .content-column .inner h3 a:hover {
  color: #ff8201;
}

.news-block-two .inner-box .content-column .inner .text {
  position: relative;
  color: #777777;
  font-size: 13px;
  margin-top: 15px;
}

.news-block-two .inner-box .content-column .inner .view-more {
  position: relative;
  font-size: 13px;
  font-weight: 800;
  color: #ff8201;
  float: right;
  margin-top: 20px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block-two .inner-box .content-column .inner .view-more:hover {
  color: #232323;
}

.news-testimonial-column .owl-controls {
  padding: 0px 0px 0px;
  position: relative;
  text-align: center;
  top: 0;
}

.news-testimonial-column .owl-theme .owl-controls .owl-nav [class*="owl-"] {
  position: relative;
  display: inline-block;
  margin: 0px 0px 0px 6px;
  padding: 1px 20px;
  border: 1px solid transparent;
  color: #cccccc;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  border-radius: 50px;
  text-transform: uppercase;
  background: none;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  font-family: "Catamaran", sans-serif;
}

.news-testimonial-column
  .owl-theme
  .owl-controls
  .owl-nav
  [class*="owl-"]:hover {
  background: #ffffff !important;
  border-color: #7f7f7f;
  color: #7f7f7f !important;
}

.news-testimonial-column .owl-dots {
  display: none !important;
}

/*** 

====================================================================
	Sponsors Style One
====================================================================

***/

.sponsors-style-one {
  position: relative;
  padding: 85px 0px;
  background: #fafafa;
  text-align: center;
}

.sponsors-style-one .owl-controls {
  display: none !important;
}

.sponsors-style-one .sponsors-outer .slide-item .image-box img {
  display: inline-block;
  max-width: 100% !important;
  width: auto !important;
  height: auto !important;
  opacity: 0.8;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.sponsors-style-one .sponsors-slider img:hover {
  opacity: 1;
}

/*** 

====================================================================
	App Section
====================================================================

***/

.app-section {
  position: relative;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fafafa;
  overflow: hidden;
  z-index: 2;
}

.app-section .content-column {
  position: relative;
  padding: 140px 15px 100px;
}

.app-section .content-column .inner-box {
  position: relative;
}

.app-section .content-column .inner-box h2 {
  position: relative;
  font-weight: 800;
  color: #232323;
  font-size: 24px;
  text-transform: uppercase;
}

.app-section .content-column .inner-box .dark-text {
  position: relative;
  color: #232323;
  font-size: 14px;
  margin-top: 6px;
}

.app-section .content-column .inner-box .text {
  position: relative;
  color: #777777;
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: 30px;
}

.app-section .content-column .inner-box .app-btn {
  position: relative;
  padding: 15px 30px 15px 90px;
  border-radius: 50px;
  text-align: left;
  font-size: 14px;
  margin-bottom: 15px;
  display: inline-block;
  border: 2px solid #ff8201;
  margin-right: 15px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.app-section .content-column .inner-box .app-btn .icon {
  position: absolute;
  left: 30px;
  top: 25px;
  font-size: 42px;
  color: #ff8201;
}

.app-section .content-column .inner-box .app-btn .app-name {
  position: relative;
  font-weight: 900;
  font-size: 22px;
}

.app-section .content-column .inner-box .app-btn:hover {
  background-color: #ff8201;
  color: #ffffff;
}

.app-section .content-column .inner-box .app-btn:hover .icon {
  color: #ffffff;
}

.app-section .image-column {
  position: relative;
  text-align: center;
  padding-top: 130px;
}

.app-section .image-column .image img {
  max-width: 100%;
  display: inline-block;
}

/*** 

====================================================================
	Services Section Two
====================================================================

***/

.services-section-two {
  position: relative;
  padding: 140px 0px 100px;
  overflow: hidden;
}

.services-section-two .sec-title {
  margin-bottom: 45px;
}

.services-section-two .right-image {
  position: absolute;
  bottom: -50px;
  left: 100%;
}

.services-section-two .right-image img {
  max-width: none;
}

.services-section-two .services-inner-content {
  position: relative;
  max-width: 830px;
}

.services-block-two {
  position: relative;
  margin-bottom: 65px;
  z-index: 1;
}

.services-block-two .inner-box {
  position: relative;
  padding-left: 110px;
}

.services-block-two .inner-box .icon-box {
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -46px;
  width: 92px;
  height: 92px;
  color: #6d6e70;
  font-size: 34px;
  line-height: 78px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 25px;
  background-color: #f9f9f9;
  border: 5px solid transparent;
}

.services-block-two .inner-box .icon-box .icon-inner {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto;
  top: 1px;
}

.services-block-two .inner-box .icon-box .icon-inner {
  position: relative;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.services-block-two .inner-box h3 {
  position: relative;
  line-height: 1em;
}

.services-block-two .inner-box h3 a {
  position: relative;
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.services-block-two .inner-box .text {
  position: relative;
  color: #777777;
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;
}

.services-block-two .inner-box:hover h3 a {
  color: #ff8201;
}

.services-block-two .inner-box:hover .icon-box {
  background-color: rgba(255, 130, 1, 0.3);
}

.services-block-two .inner-box:hover .icon-box .icon-inner {
  background-color: #ff8201;
  color: #ffffff;
}

/*** 

====================================================================
	News Section Two
====================================================================

***/

.news-section-two {
  position: relative;
  padding: 150px 0px 90px;
}

.news-block-three {
  position: relative;
  margin-bottom: 60px;
}

.news-block-three .inner-box {
  position: relative;
  border: 1px solid #e5e5e5;
}

.news-block-three .inner-box .image-box {
  position: relative;
}

.news-block-three .inner-box .image-box img {
  position: relative;
  width: 100%;
  display: block;
}

.news-block-three .inner-box .lower-box {
  position: relative;
  padding: 25px 38px;
}

.news-block-three .inner-box .lower-box .posted {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  margin-bottom: 10px;
}

.news-block-three .inner-box .lower-box .posted span {
  color: #ff8201;
}

.news-block-three .inner-box .lower-box h3 {
  position: relative;
  line-height: 1.2em;
  padding-bottom: 22px;
  font-weight: 700;
  font-size: 18px;
  color: #232323;
  border-bottom: 1px solid #dddddd;
}

.news-block-three .inner-box .lower-box h3 a {
  position: relative;
  color: #232323;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block-three .inner-box .lower-box h3 a:hover {
  color: #ff8201;
}

.news-block-three .inner-box .lower-box .text {
  position: relative;
  color: #777777;
  font-size: 14px;
  line-height: 1.6em;
  margin-top: 28px;
}

.news-block-three .inner-box .lower-box .view-more {
  position: relative;
  font-size: 13px;
  color: #ff8201;
  font-weight: 800;
  float: right;
  margin-top: 30px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block-three .inner-box .lower-box .view-more:hover {
  color: #232323;
}

.news-block-three.style-two .inner-box {
  border: 0px;
}

.news-block-three.style-two .inner-box .lower-box {
  padding: 25px 0px 0px;
}

.news-block-three.style-two .inner-box .lower-box .text blockquote {
  position: relative;
  padding: 48px 70px;
  border: 0px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #232323;
  line-height: 26px;
  background: #fafafa url(../images/icons/curve.jpg) right bottom no-repeat;
}

.news-block-three.style-two .inner-box .lower-box .text blockquote:before {
  content: "\f150";
  position: absolute;
  left: 28px;
  top: 50%;
  font-size: 28px;
  width: 20px;
  line-height: 24px;
  color: #ededed;
  font-family: "Flaticon";
  margin-top: -15px;
}

/*Post Share Options*/

.news-block-three .post-share-options {
  position: relative;
  margin-top: 25px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dddddd;
}

.news-block-three .post-share-options h5 {
  font-size: 14px;
  color: #232323;
  font-weight: 400;
  padding: 3px 0px;
}

.news-block-three .post-share-options .social-links {
  position: relative;
}

.news-block-three .post-share-options .social-links a {
  position: relative;
  color: #777777;
  margin: 2px 6px;
  font-size: 14px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block-three .post-share-options .social-links a:hover {
  color: #ff8201;
}

.news-section-two .owl-controls {
  margin: 0px !important;
}

.news-section-two .owl-controls .owl-dots {
  display: none !important;
}

.news-section-two .owl-controls .owl-nav {
  margin: 0px !important;
  position: absolute;
  right: 0px;
  top: -60px;
}

.news-section-two .owl-controls .owl-nav [class*="owl-"] {
  position: relative;
  display: inline-block;
  margin: 0px 0px 0px 6px;
  padding: 2px 20px;
  border: 1px solid transparent;
  color: #cccccc;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  border-radius: 50px;
  text-transform: uppercase;
  background: none !important;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  font-family: "Catamaran", sans-serif;
}

.news-section-two .owl-controls .owl-nav [class*="owl-"]:hover {
  color: #1c1c1c;
  border-color: #888888;
}

/*** 

====================================================================
	Comments Area
====================================================================

 ***/

.sidebar-page-container .comments-area {
  position: relative;
  padding-bottom: 35px;
  margin-top: -15px;
  margin-bottom: 45px;
  border-bottom: 1px solid #dddddd;
}

.sidebar-page-container .group-title {
  position: relative;
  margin-bottom: 30px;
}

.sidebar-page-container .group-title h2 {
  font-size: 16px;
  color: #232323;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar-page-container .comments-area .comment-box {
  position: relative;
  padding: 0px 0px 5px;
  margin-bottom: 35px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.sidebar-page-container .comments-area .comment-box:nth-child(1) {
  padding-top: 0px;
}

.sidebar-page-container .comments-area .comment-box:hover {
  border-color: #222222;
}

.sidebar-page-container .comments-area .comment {
  position: relative;
  padding: 0px 0px 0px 112px;
  font-size: 14px;
}

.sidebar-page-container .comments-area .comment .comment-inner {
  position: relative;
  padding-bottom: 22px;
  padding: 25px 30px 15px;
  background-color: #fafafa;
}

.sidebar-page-container .comment-inner .comment-info .comment-reply {
  position: absolute;
  right: 30px;
  color: #8e8e8e;
  font-size: 14px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.sidebar-page-container .comment-inner .comment-info .comment-reply:hover {
  color: #ff8201;
}

.sidebar-page-container .comments-area .reply-comment {
  padding-left: 110px;
}

.sidebar-page-container .comments-area .comment-box .author-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 82px;
  border-radius: 50%;
  overflow: hidden;
}

.sidebar-page-container .comments-area .comment-box .author-thumb img {
  width: 100%;
  display: block;
}

.sidebar-page-container .comments-area .comment-info {
  margin-bottom: 25px;
  color: #3e5773;
  line-height: 24px;
  font-size: 13px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;
}

.sidebar-page-container .comments-area .comment-box strong {
  font-size: 14px;
  font-weight: 600;
  color: #232323;
  margin-right: 30px;
  display: inline-block;
  text-transform: uppercase;
}

.sidebar-page-container .comments-area .comment-box .text {
  color: #777777;
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 1.6em;
}

.sidebar-page-container .comments-area .comment-info .comment-time {
  font-size: 14px;
  color: #ff8201;
  display: inline-block;
}

.sidebar-page-container .comments-area .comment-box .theme-btn {
  padding: 5px 30px;
  font-size: 12px;
  border: 1px solid #fbca00;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/*** 

====================================================================
	Comment Form
====================================================================

 ***/

.comment-form .form-group {
  position: relative;
  margin-bottom: 30px;
}

.comment-form .form-group .field-label {
  position: relative;
  display: block;
  font-size: 13px;
  margin-bottom: 7px;
  color: #3e5773;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 26px;
  padding: 10px 20px;
  color: #aaaaaa;
  border: 1px solid #dddddd;
  height: 48px;
  font-size: 15px;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus {
  border-color: #ff8201;
}

.comment-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 10px 20px;
  color: #222222;
  border: 1px solid #dddddd;
  height: 170px;
  background: #ffffff;
  resize: none;
  font-size: 14px;
  border-radius: 2px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

/*** 

====================================================================
	Tabs Box / Tabs Syle One
====================================================================

***/

.select-car-tabs {
  position: relative;
}

.select-car-tabs .tab-buttons,
.select-car-tabs .tabs-content {
  position: relative;
}

.select-car-tabs .tab-buttons {
  position: relative;
  z-index: 1;
  top: -60px;
  text-align: right;
}

.select-car-tabs .tab-buttons .tab-btn {
  position: relative;
  display: inline-block;
  line-height: 18px;
  padding: 8px 14px 6px 14px;
  margin-left: 10px;
  text-transform: uppercase;
  color: #cccccc;
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
  border-radius: 16px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.select-car-tabs .tab-buttons .tab-btn.active-btn,
.select-car-tabs .tab-buttons .tab-btn:hover {
  color: #777777;
  border-color: #777777;
}

.select-car-tabs .tabs-content .tab {
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  visibility: hidden;
  opacity: 0;
  width: 100%;
}

.select-car-tabs .tabs-content .tab.active-tab {
  visibility: visible;
  opacity: 1;
  position: relative;
}

/*** 

====================================================================
	Page Title Style
====================================================================

***/

.page-title {
  position: relative;
  padding-top: 130px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
}

.page-title:before {
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 50%;
  position: absolute;
}

.page-title .auto-container {
  position: relative;
  z-index: 1;
}

.page-title h1 {
  position: relative;
  font-size: 32px;
  font-weight: 500;
  color: #ffffff;
  /* padding-bottom: 60px; */
  line-height: 1.4em;
}

.page-title h1:after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 10px;
  width: 1px;
  /* height: 42px; */
}
.contact_subheading {
  font-size: 18px;
  font-weight: 400;
  color: #fb561e;
  /* padding-bottom: 60px; */
  line-height: 27px;
  text-align: center;
}

.page-title .bread-crumb-outer {
  position: relative;
  display: inline-block;
  /* padding: 13px 50px; */
  /* top: 8px; */
}

.page-title .bread-crumb-outer .bread-crumb li {
  position: relative;
  float: left;
  margin-right: 30px;
  color: #fb561e;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

.page-title .bread-crumb-outer .bread-crumb li:last-child {
  margin-right: 0px;
}

.page-title .bread-crumb-outer .bread-crumb li:after {
  content: "\f105";
  font-family: "FontAwesome";
  position: absolute;
  right: -22px;
  width: 10px;
  line-height: 20px;
  font-size: 14px;
  color: #ffffff;
}

.page-title .bread-crumb-outer .bread-crumb li:last-child:after {
  display: none;
}

.page-title .bread-crumb-outer .bread-crumb li a {
  color: #ffffff;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.page-title .bread-crumb-outer .bread-crumb li a:hover {
  color: #fa6f1c;
}

.page-title .bread-crumb-outer .bread-crumb li.active {
  color: #ff8201;
}

.heading_img_container {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.contactus_img {
  height: 24px;
  width: 24px;
}
.contactus_address_heading {
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
@media screen and (max-width: 600px) {
  .heading_img_container {
    gap: 10px;
  }
  .contactus_img {
    height: 16px;
    width: 16px;
  }
  .contactus_address_heading {
    font-family: "Inter";
    font-weight: 500;
    font-size: 12px;
    line-height: 14.4px;
  }
}

/*** 

====================================================================
	Experiance Section
====================================================================

***/

.experiance-section {
  position: relative;
  padding: 150px 0px 100px;
}

.experiance-section .content-column {
  position: relative;
  padding-bottom: 200px;
  padding-right: 60px;
  margin-bottom: 50px;
  background: url(../images/resource/car-layer.png) center bottom no-repeat;
}

.experiance-section .content-column h3 {
  position: relative;
  line-height: 1.4em;
  margin-bottom: 30px;
}

.experiance-section .content-column h3 a {
  position: relative;
  font-size: 24px;
  color: #000000;
  font-weight: 900;
}

.experiance-section .content-column .text {
  position: relative;
}

.experiance-section .content-column .text p {
  position: relative;
  color: #777777;
  font-size: 14px;
  margin-bottom: 25px;
}

.experiance-section .content-column .read-more {
  position: relative;
  color: #ff8201;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
}

.experiance-section .form-column {
  position: relative;
}

.experiance-section .form-column .inner-box {
  position: relative;
  padding: 45px 30px;
  background-color: #fafafa;
}

.experiance-section .default-form .form-group .group-inner .field-label {
  margin-bottom: 10px;
}

/*** 

====================================================================
	Sidebar Page Container
====================================================================

***/

.sidebar-page-container {
  position: relative;
  padding: 150px 0px 110px;
}

.sidebar-page-container .content-side {
  margin-bottom: 30px;
}

.sidebar-page-container .cars-info-section {
  position: relative;
}

.cars-info-section .items-sorting {
  position: relative;
  margin-bottom: 50px;
  padding: 10px 20px 0px;
  background-color: #f8f8f8;
}

.cars-info-section .items-sorting .results-column h4 {
  font-size: 14px;
  font-weight: 600;
  color: #232323;
  line-height: 24px;
  padding: 10px 0px;
}

.cars-info-section .items-sorting .form-group {
  margin-bottom: 10px;
  max-width: 232px;
  width: 100%;
  float: right;
}

.cars-info-section .items-sorting select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 9px 15px 9px;
  height: 44px;
  font-size: 14px;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  background: #ffffff url(../images/icons/select-arrow.png) right center
    no-repeat;
  color: #999999;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.cars-info-section .items-sorting select option {
  text-indent: 15px;
}

.cars-info-section .items-sorting select:focus {
  border-color: #fa6f1c;
}

.cars-info-section .items-sorting .right-column .viewed-box {
  position: relative;
  float: right;
  margin-left: 5px;
  margin-bottom: 10px;
}

.cars-info-section .items-sorting .right-column .viewed-box a {
  position: relative;
  width: 45px;
  height: 42px;
  line-height: 40px;
  font-size: 18px;
  color: #cccccc;
  margin-left: 7px;
  text-align: center;
  display: inline-block;
  border: 1px solid #cccccc;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.cars-info-section .items-sorting .right-column .viewed-box a:hover,
.cars-info-section .items-sorting .right-column .viewed-box a.active {
  color: #ff8201;
  border-color: #ff8201;
}

.car-info-block {
  position: relative;
  margin-bottom: 40px;
}

.car-info-block .inner-box {
  position: relative;
  overflow: hidden;
  border: 1px solid #f5f5f5;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(234, 234, 234, 1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(234, 234, 234, 1);
  box-shadow: 0px 0px 8px 0px rgba(234, 234, 234, 1);
}

.car-info-block .inner-box .image {
  position: relative;
  overflow: hidden;
}

.car-info-block .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
  -webkit-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  transition: all 600ms ease;
}

.car-info-block .inner-box .lower-box {
  position: relative;
  padding: 25px 30px;
  border-top: 0px;
}

.car-info-block .inner-box .lower-box h3 {
  position: relative;
  line-height: 1em;
  padding-bottom: 22px;
  margin-bottom: 25px;
  border-bottom: 1px solid #dddddd;
}

.car-info-block .inner-box .lower-box h3 a {
  color: #232323;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.car-info-block .inner-box .lower-box h3 a:hover {
  color: #ff8201;
}

.car-info-block .inner-box .lower-box .more-info {
  position: relative;
}

.car-info-block .inner-box .lower-box ul {
  position: relative;
}

.car-info-block .inner-box .lower-box ul li {
  position: relative;
  color: #777777;
  font-size: 14px;
  margin-bottom: 8px;
}

.car-info-block .inner-box .lower-box ul li:last-child {
  margin-bottom: 0px;
}

.car-info-block .inner-box .lower-box ul li span {
  color: #232323;
}

.car-info-block .inner-box .lower-box .more-info .price-day {
  position: absolute;
  right: 0px;
  top: 50%;
  font-size: 32px;
  color: #ff8201;
  font-weight: 700;
  line-height: 1em;
  margin-top: -19px;
}

.car-info-block .inner-box .lower-box .more-info .price-day span {
  position: relative;
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}

.car-info-block .inner-box:hover .image img {
  -webkit-transform: rotate(4deg) scale(1.1);
  -ms-transform: rotate(4deg) scale(1.1);
  -o-transform: rotate(4deg) scale(1.1);
  -moz-transform: rotate(4deg) scale(1.1);
  transform: rotate(4deg) scale(1.1);
}

.sidebar-page-container .sidebar-form.style-two .widget-content {
  padding: 30px 30px 15px;
}

.sidebar-page-container .sidebar-form.style-two .field-label {
  font-weight: 500;
  color: #232323;
  margin-bottom: 12px;
}

.sidebar-page-container .sidebar-form.style-two .input-label {
  line-height: 1em;
  margin-bottom: 15px;
}

.sidebar-page-container .sidebar-form.style-two .select-box {
  margin-right: 0px;
}

.sidebar-page-container .sidebar-form.style-two .number-of-people {
  float: none;
}

.sidebar-page-container .sidebar-form h3 {
  position: relative;
  color: #232323;
  font-size: 18px;
  font-weight: 700;
  padding: 26px 0px;
  text-align: center;
  background-color: #f4f4f4;
  text-transform: uppercase;
}

.sidebar-page-container .sidebar-form .default-form .btn-style-one {
  display: block;
  width: 100%;
}

/* Widget */

.sidebar-widget {
  position: relative;
  margin-bottom: 50px;
}

.rangeslider-widget {
  position: relative;
  margin-bottom: 35px;
}

.rangeslider-widget .field-label {
  font-weight: 700;
  color: #232323;
  font-size: 14px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.rangeslider-widget .val-box {
  position: relative;
  display: inline-block;
  margin: 7px 5px 7px 0px;
  line-height: 24px;
  font-size: 14px;
  border: 1px solid #dadada;
  padding: 8px 10px;
  width: 70px;
  text-align: center;
}

.rangeslider-widget button {
  position: relative;
  display: inline-block;
  line-height: 24px;
  border: 1px solid #272727;
  padding: 8px 25px;
  background: #272727;
  color: #ffffff;
  border-radius: 3px;
}

.rangeslider-widget .noUi-connect {
  background: #f8ad60 !important;
  box-shadow: none;
}

.rangeslider-widget .noUi-background {
  background: #eeeeee !important;
  border-color: #eeeeee;
}

.rangeslider-widget .noUi-handle {
  background: #ff972c !important;
  border-radius: 50% !important;
  box-shadow: none;
  border: 4px solid #ff8201;
  cursor: pointer;
}

/*Video Box Widget*/

.video-widget {
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video-widget .widget-inner {
  position: relative;
}

.video-widget .widget-inner .play-box {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  padding: 100px 0px 20px;
  background: rgba(0, 0, 0, 0);
  transition: all 700ms ease;
}

.video-widget .widget-inner .play-box:hover {
  background: rgba(0, 0, 0, 0.5);
}

.video-widget .widget-inner .play-box .icon {
  position: relative;
  width: 48px;
  height: 48px;
  color: #ffffff;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  display: inline-block;
  border: 3px solid #ffffff;
}

.video-widget .widget-inner .play-box h4 {
  position: relative;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  margin-top: 60px;
  text-transform: uppercase;
}

.sidebar-page-container .cars-info-section.list-view {
  position: relative;
}

.cars-info-section.list-view .car-info-block .car-column {
  position: relative;
  padding: 0px;
}

.cars-info-section.list-view .car-info-block .inner-box {
  box-shadow: none;
}

.cars-info-section.list-view .car-info-block .car-column .lower-box {
  border-bottom: 0px;
  padding: 33px 40px;
}

/*Search Widget*/

.sidebar .search-box .form-group {
  position: relative;
  margin: 0px;
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"] {
  position: relative;
  line-height: 30px;
  padding: 10px 40px 10px 20px;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  color: #1c1c1c;
  display: block;
  font-size: 13px;
  width: 100%;
  height: 52px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sidebar .search-box .form-group input:focus {
  border-color: #ff8201;
}

.sidebar .search-box .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 52px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  font-size: 15px;
  color: #ffffff;
  background: #ff8201;
  font-weight: normal;
}

/*List*/

.sidebar .sidebar-widget .widget-box {
  background-color: #fafafa;
}

.sidebar .sidebar-widget .sidebar-title {
  position: relative;
  padding: 22px 30px;
  border-bottom: 1px solid #dddddd;
}

.sidebar .sidebar-widget .sidebar-title h3 {
  position: relative;
  color: #232323;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar .sidebar-widget .widget-content {
  position: relative;
  padding: 40px 30px 15px;
}

.sidebar .list {
  position: relative;
}

.sidebar .list li {
  position: relative;
  line-height: 24px;
}

.sidebar .list li a {
  position: relative;
  display: block;
  color: #777777;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 0px 8px 20px;
  transition: all 500ms ease;
}

.sidebar .list li:first-child a {
  padding-top: 0px;
}

.sidebar .list li:last-child {
  border-bottom: none;
}

.sidebar .list li a .count {
  display: block;
  float: right;
}

.sidebar .list li a:before {
  position: absolute;
  content: "\f105";
  font-size: 12px;
  left: 0;
  width: 20px;
  line-height: 22px;
  font-family: "FontAwesome";
}

.sidebar .list li a:hover {
  color: #ff8201;
}

/*** 

====================================================================
	FAQs Section style
====================================================================

***/

.faqs-section {
  position: relative;
  padding: 100px 0px;
}

.accordion-box {
  position: relative;
}

.accordion-box .block {
  position: relative;
  margin-bottom: 20px;
}

.block .acc-btn {
  position: relative;
  font-size: 14px;
  margin-bottom: 0px;
  cursor: pointer;
  background: none;
  line-height: 30px;
  padding: 15px 25px 15px 80px;
  overflow: hidden;
  font-weight: 700;
  color: #212121;
  background-color: #fafafa;
  border-radius: 2px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.accordion-box .block .acc-btn.active {
  position: relative;
  color: #ffffff;
  background-color: #ff8201;
}

.accordion-box .block .icon-outer {
  position: absolute;
  left: 20px;
  top: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  color: #777777;
  background: #eeeeee;
  border-radius: 2px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.accordion-box .block .icon-outer .icon {
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  width: 100%;
  height: 40px;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.accordion-box .block .icon-outer .icon-plus {
  opacity: 1;
}

.accordion-box .block .icon-outer .icon-minus {
  opacity: 0;
}

.accordion-box .block .acc-btn.active .icon-outer .icon-minus {
  opacity: 1;
  color: #ffffff;
}

.accordion-box .block .acc-btn.active .icon-outer .icon-plus {
  opacity: 0;
}

.accordion-box .block .acc-btn.active .icon-outer {
  /* background: #e57400; */
  background: #fff;
}

.accordion-box.style-two .block .acc-btn.active .icon-outer {
  background: none;
  color: #fc721e;
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
}

.accordion-box .block .acc-content.current {
  display: block;
}

.accordion-box .block .content {
  position: relative;
  font-size: 14px;
  padding: 25px 0px 0px 75px;
  color: #777777;
}

.accordion-box .block .content p {
  margin-bottom: 20px;
  line-height: 1.8em;
}

.accordion-box .block .content p:last-child {
  margin-bottom: 0px;
}

.faqs-section .faq-form-column {
  position: relative;
}

.faqs-section .faq-form-column .faq-form-inner {
  position: relative;
  padding: 35px 30px 10px;
  background-color: #fafafa;
}

.faqs-section .faq-form-column .faq-form-inner h3 {
  position: relative;
  font-size: 16px;
  color: #232323;
  font-weight: 700;
  margin-bottom: 26px;
  text-transform: uppercase;
}

.faqs-section .faq-form-column .faq-form-inner .field-label {
  font-weight: 400;
  color: #232323;
  margin-bottom: 12px;
}

.faqs-section .faq-form-column .faq-form-inner .form-group .btn-style-one {
  width: 100%;
  display: block;
}

/*** 

====================================================================
	Gallery Section
====================================================================

***/

.gallery-section {
  position: relative;
  padding: 150px 0px 120px;
}

.gallery-section .filter-tabs {
  position: relative;
  margin-bottom: 50px;
  text-align: center;
}

.gallery-section .filter-tabs li {
  position: relative;
  display: inline-block;
  text-align: center;
  color: #777777;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 15px 0px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.gallery-section .filter-tabs li:after {
  position: absolute;
  right: -18px;
  top: 0px;
  color: #777777;
  font-size: 14px;
  content: "/";
}

.gallery-section .filter-tabs li:last-child::after {
  display: none;
}

.gallery-section .filter-tabs li:hover,
.gallery-section .filter-tabs li.active {
  color: #ff8201;
}

.gallery-item {
  position: relative;
  float: left;
  display: none;
  margin-bottom: 30px;
}

.gallery-item .inner-box {
  position: relative;
  width: 100%;
  display: block;
  background: #ffffff;
  overflow: hidden;
}

.gallery-item .image {
  position: relative;
  display: block;
  width: 100%;
}

.gallery-item .image img {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.gallery-item:hover .image img {
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.gallery-item .zoom-btn {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  font-size: 26px;
  color: #ffffff;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  transform: scale(0, 0);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.gallery-item .zoom-btn .icon {
  position: relative;
  display: inline-block;
  line-height: 60px;
  top: 50%;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background-color: #ff8201;
  margin-top: -31px;
}

.gallery-item .inner-box:hover .zoom-btn {
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
}

.sortable-masonry .gallery-item {
  display: block;
}

/*** 

====================================================================
	Error Section
====================================================================

***/

.error-section {
  position: relative;
  text-align: center;
  padding: 150px 0px 140px;
}

.error-section .error-text {
  position: relative;
  text-align: center;
  background: url(../images/icons/error-image.png) center center no-repeat;
}

.error-section .error-text h2 {
  position: relative;
  color: #777777;
  font-size: 48px;
  font-weight: 800;
  line-height: 1em;
  padding: 80px 0px;
}

.error-section .text {
  position: relative;
  color: #000000;
  font-size: 24px;
  margin: 30px 0px;
}

/*Search Website*/

.search-website {
  position: relative;
  max-width: 370px;
  margin: 0 auto 0px;
}

.search-website .form-group {
  position: relative;
  margin-bottom: 0px;
  text-align: left;
}

.search-website .form-group input[type="text"],
.search-website .form-group input[type="search"],
.search-website .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 52px;
  line-height: 24px;
  padding: 10px 15px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.search-website .form-group input[type="text"]:focus,
.search-website .form-group input[type="search"]:focus,
.search-website .form-group textarea:focus {
  border-color: #ff8201;
}

.search-website .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 50px;
  height: 52px;
  text-align: center;
  margin: 0px;
  padding: 10px 0px;
  line-height: 24px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  background: #ff8201;
  color: #ffffff;
  letter-spacing: 2px;
  border-radius: 0px 2px 2px 0px;
}

/*** 

====================================================================
	Blog Section
====================================================================

***/

.blog-section {
  position: relative;
  padding: 150px 0px 140px;
}

/*** 

====================================================================
	Styled Pagination
====================================================================

***/

.styled-pagination {
  position: relative;
}

.styled-pagination li {
  position: relative;
  display: inline-block;
  margin: 0px 5px 7px 0px;
}

.styled-pagination li a {
  position: relative;
  display: block;
  line-height: 20px;
  padding: 8px 5px;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 38px;
  color: #777777;
  text-align: center;
  border-radius: 50%;
  font-weight: 700;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
}

.styled-pagination li a.next {
  padding: 10px 24px 7px;
  border-radius: 50px;
  border: 1px solid #777777;
}

.styled-pagination li a:hover,
.styled-pagination li a.active,
.styled-pagination li a.next:hover {
  color: #ffffff;
  border-color: #ff8201;
  background-color: #ff8201;
}

/*** 

====================================================================
	Contact Section
====================================================================

***/

.contact-section {
  position: relative;
  padding: 30px 80px 30px;
}
@media screen and (max-width: 600px) {
  .contact-section {
    position: relative;
    padding: 30px 20px 30px;
  }
}

.contact-section .map-column {
  position: relative;
  margin-bottom: 50px;
}

.contact-section .map-column .map-content {
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 25px 30px;
  background-color: #ffffff;
}

.contact-section .map-column .map-content h3 {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: #232323;
}

.contact-section .map-column .map-content .text {
  color: #aaaaaa;
  font-size: 16px;
  margin-top: 8px;
}

.contact-section .map-column .map-content .map-view {
  position: relative;
  color: #0189e2;
  font-size: 14px;
  margin-top: 15px;
  display: inline-block;
  text-decoration: underline;
}

.contact-section .row {
  position: relative;
  margin: 30px -33px;
}

.contact-section .column {
  position: relative;
  padding: 0px 33px;
}

/*** 

====================================================================
	Contact Form
====================================================================

 ***/

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form .group-title {
  position: relative;
}

.contact-form .group-title h3 {
  position: relative;
  color: #232323;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.contact-form .group-title .text {
  position: relative;
  color: #777777;
  font-size: 14px;
  margin-bottom: 35px;
}

.contact-form .form-group .field-label {
  position: relative;
  display: block;
  font-size: 13px;
  margin-bottom: 7px;
  color: #3e5773;
}

.contact-section .column .contact-form form .row {
  margin: 0px -15px;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="password"],
.contact-form .form-group input[type="tel"],
.contact-form .form-group input[type="email"],
.contact-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 26px;
  padding: 10px 20px;
  color: #333;
  border: 1px solid #dddddd;
  height: 48px;
  font-size: 15px;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group input[type="password"]:focus,
.contact-form .form-group input[type="tel"]:focus,
.contact-form .form-group input[type="email"]:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus {
  border-color: #ff8201;
}

.contact-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 10px 20px;
  color: #222222;
  border: 1px solid #dddddd;
  height: 170px;
  background: #ffffff;
  resize: none;
  font-size: 14px;
  border-radius: 2px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.contact-form .form-group button {
  margin-top: 20px;
}

/*contact info*/

.contact-info {
  position: relative;
  margin-top: 25px;
}

.contact-info li {
  position: relative;
  color: #777777;
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 30px;
  display: inline-block;
}

.contact-info li .icon {
  position: relative;
  font-size: 18px;
  margin-right: 12px;
  line-height: 1em;
  top: 2px;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error {
  border-color: #ff0000 !important;
}

.contact-form label.error {
  display: block;
  line-height: 24px;
  padding: 5px 0px 0px;
  margin: 0px;
  text-transform: uppercase;
  font-size: 11px;
  color: #ff0000;
  font-weight: 500;
}

.ui-widget.ui-widget-content {
  border: 1px solid #e0e0e0;
  border-top: none;
  font-family: "Poppins", sans-serif;
}

.ui-menu .ui-menu-item {
  font-size: 14px;
  border-bottom: 1px solid #f0f0f0;
}

.ui-menu .ui-menu-item:last-child {
  border: none;
}

.ui-menu .ui-menu-item-wrapper {
  position: relative;
  display: block;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 24px;
}

/*** 

====================================================================
	Car Single
====================================================================

***/

.single-car {
  position: relative;
  padding: 130px 0px 80px;
}

.single-car .basic-details {
  position: relative;
  margin-bottom: 30px;
}

.single-car .basic-details .carousel-column,
.single-car .basic-details .info-column {
  margin-bottom: 30px;
}

.single-car .basic-details .image-column img {
  display: block;
  width: 100%;
  height: auto;
}

.single-car .basic-details .prod-image-carousel {
  position: relative;
  padding: 0px 50px;
}

.single-car .basic-details .prod-thumbs-carousel img {
  border-radius: 3px;
}

.sponsors-section .owl-theme .owl-controls,
.sponsors-section .owl-theme .owl-controls .owl-nav {
  margin: 0px;
  padding: 0px;
}

.single-car .basic-details .owl-theme .owl-controls .owl-dots {
  display: none !important;
}

.single-car .basic-details .owl-theme .owl-controls .owl-nav [class*="owl-"] {
  position: absolute;
  top: 50%;
  padding: 0px;
  margin: 0px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  line-height: 36px;
  border: 2px solid #d0d0d0;
  color: #d0d0d0;
  text-align: center;
  font-size: 18px;
  background: none;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.single-car
  .basic-details
  .owl-theme
  .owl-controls
  .owl-nav
  [class*="owl-prev"] {
  left: 0px;
}

.single-car
  .basic-details
  .owl-theme
  .owl-controls
  .owl-nav
  [class*="owl-next"] {
  right: 0px;
}

.single-car
  .basic-details
  .owl-theme
  .owl-controls
  .owl-nav
  [class*="owl-"]:hover {
  border-color: #ff8201;
  color: #ff8201;
}

.single-car .sidebar-title {
  position: relative;
  margin-bottom: 20px;
}

.single-car .sidebar-title h3 {
  position: relative;
  line-height: 1.3em;
  font-size: 18px;
  color: #222222;
  font-weight: 500;
  text-transform: capitalize;
}

.single-car .basic-details .info-column {
  position: relative;
  padding-left: 40px;
}

.single-car .basic-details .info-column .theme-btn {
  display: block;
  width: 100%;
  text-align: center;
}

.single-car .basic-details .info-outer {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-bottom: 20px;
}

.single-car .basic-details .info-outer .upper-info {
  position: relative;
  padding: 25px 30px 20px;
}

.single-car .basic-details .info-outer .list {
  position: relative;
}

.single-car .basic-details .info-outer .list li {
  position: relative;
  line-height: 24px;
  margin-bottom: 10px;
}

.single-car .basic-details .info-outer .list li .col {
  position: relative;
  float: left;
  width: 50%;
}

.single-car .basic-details .info-outer .list li .col:nth-child(2) {
  color: #ff8201;
}

.single-car .basic-details .info-outer .list li:last-child {
  margin-bottom: 0px;
}

.single-car .basic-details .info-outer .list li a {
  position: relative;
  display: block;
  color: #777777;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 0px;
}

.single-car .basic-details .info-outer .list li a:hover {
  color: #ff8201;
}

.single-car .basic-details .info-outer .info {
  position: relative;
  text-align: center;
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: #222222;
}

.single-car .basic-details .info-outer .info li {
  position: relative;
  float: left;
  width: 50%;
  line-height: 24px;
  padding: 8px 10px;
  border-right: 1px solid #e0e0e0;
}

.single-car .basic-details .info-outer .info .price {
  color: #ff8201;
}

.single-car .basic-details .info-outer .info .price strong {
  font-family: "Catamaran", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.single-car .basic-details .info-outer .info li:nth-child(2n + 2) {
  border-right: none;
}

/*** 

====================================================================
	Product Tabs Style
====================================================================

***/

.product-info-tabs {
  position: relative;
}

.prod-tabs {
  position: relative;
}

.prod-tabs .tab-btns {
  position: relative;
  margin-bottom: 50px;
  font-family: "Catamaran", sans-serif;
}

.prod-tabs .tab-btns .tab-btn {
  position: relative;
  float: left;
  font-size: 15px;
  color: #222222;
  text-transform: capitalize;
  font-weight: 400;
  padding: 8px 50px;
  line-height: 24px;
  border: 1px solid #e0e0e0;
  margin-right: 10px;
  border-radius: 3px;
  cursor: pointer;
}

.prod-tabs .tab-btns .tab-btn.active-btn {
  color: #ff8201;
  border-color: #ff8201;
}

.prod-tabs .tabs-container {
  position: relative;
}

.prod-tabs .tabs-container .tab {
  position: relative;
  display: none;
}

.prod-tabs .tabs-container .tab.active-tab {
  display: block;
}

.prod-tabs .tabs-container .tab p,
.prod-tabs .tabs-container .tab h2,
.prod-tabs .tabs-container .tab h3,
.prod-tabs .tabs-container .tab h4,
.prod-tabs .tabs-container .tab h5,
.prod-tabs .tabs-container .tab h6 {
  margin-bottom: 20px;
}

.prod-tabs .tabs-container .tab h3 {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  color: #222222;
  margin-bottom: 15px;
}

.prod-tabs .tabs-container .tab h4 {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: #222222;
  margin-bottom: 15px;
}

.prod-tabs .tabs-container .tab p {
  font-size: 14px;
}

.prod-tabs .reviews-container {
  position: relative;
}

.prod-tabs .reviews-container .review-box {
  position: relative;
  margin-bottom: 30px;
  padding-left: 100px;
  min-height: 100px;
}

.prod-tabs .reviews-container .rev-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 82px;
  height: 82px;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  border-radius: 3px;
}

.prod-tabs .reviews-container .rev-thumb img {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 3px;
}

.prod-tabs .reviews-container .rev-content {
  position: relative;
  padding: 15px;
  border: 1px solid #e0e0e0;
  background: #ffffff;
}

.prod-tabs .reviews-container .rating {
  color: #ff9c01;
  font-size: 13px;
}

.prod-tabs .reviews-container .rating .fa {
  margin-right: 1px;
}

.prod-tabs .reviews-container .rev-info {
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.prod-tabs .reviews-container .rev-text {
  font-size: 14px;
}

.prod-tabs .add-review {
  position: relative;
  margin: 30px 0px 20px;
  font-size: 14px;
}

.prod-tabs .add-review .rating {
  position: relative;
  padding-top: 8px;
}

.prod-tabs .add-review .rating .rate-box {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  color: #555555;
}

.prod-tabs .add-review .rating .rate-box:hover,
.prod-tabs .add-review .rating .rate-box:focus,
.prod-tabs .add-review .rating .rate-box:active {
  color: #fa6f1c;
}

.prod-tabs .add-review label {
  position: relative;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 400;
  color: #303030;
}

.prod-tabs .add-review h3 {
  margin-bottom: 20px;
}

.prod-tabs .add-review .form-group {
  position: relative;
  margin-bottom: 20px;
}

.prod-tabs .add-review .form-group input[type="text"],
.prod-tabs .add-review .form-group input[type="password"],
.prod-tabs .add-review .form-group input[type="tel"],
.prod-tabs .add-review .form-group input[type="email"],
.prod-tabs .add-review .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 22px;
  padding: 9px 15px;
  color: #222222;
  border: 1px solid #e0e0e0;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.prod-tabs .add-review .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 22px;
  padding: 8px 15px;
  color: #222222;
  border: 1px solid #e0e0e0;
  height: 150px;
  resize: none;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.prod-tabs .add-review button {
  padding: 8px 35px;
}

/*** 

====================================================================
	Default Video Box
====================================================================

***/

.default-video-box {
  position: relative;
  text-align: center;
}

.fun-facts-section .default-video-box {
  top: -70px;
}

.default-video-box .inner-box {
  position: relative;
  display: block;
}

.default-video-box .image-box {
  position: relative;
  display: block;
}

.default-video-box .image-box img {
  position: relative;
  display: block;
  width: 100%;
}

.default-video-box .overlay-link {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(45, 45, 45, 0.2);
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.default-video-box .overlay-link:hover {
  background: rgba(45, 45, 45, 0.4);
}

.default-video-box .overlay-link .icon-box {
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: 50%;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  background: none;
  color: #ffffff;
  border: 8px solid rgba(255, 130, 1, 0.4);
  border-radius: 50%;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.default-video-box .overlay-link .icon-box .icon {
  position: relative;
  padding-left: 3px;
  display: block;
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 24px;
  background: #ff8201;
  border-radius: 50%;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.prod-tabs .two-column {
  position: relative;
}

.prod-tabs .two-column .column {
  position: relative;
  margin-bottom: 20px;
}

.prod-tabs .two-column .image img {
  position: relative;
  display: block;
  width: 100%;
}

.styled-list-one {
  position: relative;
  margin-bottom: 30px;
}

.styled-list-one li {
  position: relative;
  margin-bottom: 10px;
  font-style: italic;
  line-height: 24px;
  font-size: 14px;
  padding-left: 25px;
}

.styled-list-one li:before {
  content: "\f0a9";
  font-family: "FontAwesome";
  position: absolute;
  left: 0px;
  top: 0px;
  line-height: 24px;
  font-size: 13px;
  color: #ff8201;
  font-style: normal;
  display: block;
}

.prod-tabs .default-video-box .overlay-link {
  background: rgba(0, 0, 0, 0.6);
}

/*** 

====================================================================
	Billing Section
====================================================================

***/

.billing-section {
  position: relative;
  padding: 130px 0px;
}

.billing-section .sec-title {
  position: relative;
  margin-bottom: 30px;
}

.billing-section .billing-form {
  position: relative;
  margin-bottom: 70px;
}

.billing-section .billing-details .product-column {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.billing-section .billing-details .product-column .inner-box {
  position: relative;
  padding: 35px 30px 40px;
  text-align: center;
  background: #f7f7f7;
  border-radius: 3px;
}

.billing-section .billing-details .product-column h3 {
  font-weight: 500;
  color: #222222;
  font-size: 18px;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.billing-section .billing-details .product-column h3 a {
  color: #222222;
}

.billing-section .billing-details .product-column h3 a:hover {
  color: #ff8201;
}

.billing-section .billing-details .product-column .image-box {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.billing-section .billing-details .product-column .image-box img {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.billing-section .billing-details .product-column.lower-content {
  position: relative;
  padding: 0px 20px;
}

.billing-section .billing-details .product-column .info {
  position: relative;
  display: inline-block;
  border: 1px solid #d0d0d0;
  margin-bottom: 20px;
  color: #222222;
  border-radius: 3px;
}

.billing-section .billing-details .product-column .info li {
  position: relative;
  float: left;
  line-height: 24px;
  padding: 8px 25px;
  border-right: 1px solid #d0d0d0;
}

.billing-section .billing-details .product-column .info .price {
  color: #ff8201;
}

.billing-section .billing-details .product-column .info .price strong {
  font-family: "Catamaran", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.billing-section .billing-details .product-column .info li:nth-child(2n + 2) {
  border-right: none;
}

.billing-section .billing-details .product-column .theme-btn {
  padding: 10px 45px;
}

.billing-section .billing-details .billing-column {
  position: relative;
  margin-bottom: 30px;
}

.billing-section .billing-details .billing-column .text {
  position: relative;
  font-size: 14px;
  margin-bottom: 25px;
}

.billing-section .billing-details .billing-column .inner-box {
  position: relative;
  padding: 40px 40px;
  background: #f7f7f7;
  border-radius: 3px;
}

.billing-section .billing-details .billing-column .theme-btn {
  position: relative;
  padding: 10px 45px;
  margin-top: 10px;
}

.billing-section .billing-details .billing-column .check-box {
  position: relative;
  margin-bottom: 23px;
}

.billing-section .billing-details .billing-column .check-box input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.billing-section .billing-details .billing-column .check-box label {
  position: relative;
  display: block;
  font-weight: 400;
  font-family: "Catamaran", sans-serif;
  padding-left: 26px;
  color: #222222;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.billing-section .billing-details .billing-column .check-box label img {
  position: relative;
  padding-top: 8px;
}

.billing-section
  .billing-details
  .billing-column
  .check-box
  input:checked
  + label {
  color: #ff8201;
}

.billing-section .billing-details .billing-column .check-box label .icon {
  position: absolute;
  left: 0px;
  top: 4px;
  display: block;
  width: 16px;
  height: 16px;
}

.billing-section .billing-details .billing-column .check-box label .square {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 14px;
  height: 14px;
  border: 1px solid #d0d0d0;
}

.billing-section .billing-details .billing-column .check-box label .fa {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #ff8201;
  padding-right: 2px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  text-align: center;
  opacity: 0;
}

.billing-section
  .billing-details
  .billing-column
  .check-box
  input:checked
  + label
  .fa {
  opacity: 1;
}

/*** 

====================================================================
	Default Form Styling
====================================================================

***/

.billing-section .form-group {
  position: relative;
  margin-bottom: 20px;
}

.billing-section .form-group .field-inner {
  position: relative;
}

.billing-section .form-group .field-inner .input-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  line-height: 20px;
  font-size: 12px;
  color: #ff8201;
}

.billing-section .field-label {
  display: block;
  line-height: 24px;
  margin-bottom: 10px;
  color: #777777;
  font-weight: 400;
  font-size: 16px;
}

.billing-section .form-group .field-label sup,
.billing-section .form-group .field-label .req {
  color: #ff8201;
  font-size: 16px;
  padding-left: 3px;
}

.billing-section input[type="text"],
.billing-section input[type="email"],
.billing-section input[type="password"],
.billing-section input[type="tel"],
.billing-section input[type="url"],
.billing-section select,
.billing-section textarea {
  display: block;
  width: 100%;
  line-height: 22px;
  height: 44px;
  font-size: 14px;
  border: 1px solid #e3e3e3;
  padding: 10px 20px;
  background-color: #ffffff;
  color: #222222;
  border-radius: 3px;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.billing-section select {
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  background: #ffffff url(../images/icons/select-arrow.png) right center
    no-repeat;
}

.billing-section textarea {
  height: 120px;
  resize: none;
}

.billing-section select option {
  text-indent: 20px;
}

.billing-section input:focus,
.billing-section select:focus,
.billing-section textarea:focus {
  border-color: #ff8201;
}

.billing-section input.error,
.billing-section select.error,
.billing-section textarea.error {
  border-color: #ff0000 !important;
}

.billing-section label.error {
  display: block;
  line-height: 24px;
  padding: 5px 0px 0px;
  margin: 0px;
  text-transform: uppercase;
  font-size: 12px;
  color: #ff0000;
  font-weight: 500;
}

.billing-section .billing-details .check-box {
  position: relative;
  padding: 0px 0px 0px;
}

.billing-section .billing-form .check-box {
  padding: 15px 0px 0px;
}

.billing-section .check-box input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.billing-section .check-box label {
  position: relative;
  display: block;
  font-weight: normal;
  padding-left: 26px;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.billing-section .check-box input:checked + label {
  color: #ff8201;
}

.billing-section .check-box label .icon {
  position: absolute;
  left: 0px;
  top: 4px;
  display: block;
  width: 16px;
  height: 16px;
}

.billing-section .check-box label .square {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 14px;
  height: 14px;
  border: 1px solid #eeeeee;
}

.billing-section .check-box label .fa {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #ff8201;
  padding-right: 2px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  text-align: center;
  opacity: 0;
}

.billing-section .check-box input:checked + label .fa {
  opacity: 1;
}

/*** 

====================================================================
	Confirmation Popup
====================================================================

***/

body.popup-visible {
  height: 100%;
  overflow: hidden;
}

.backdrop-overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.popup-outer {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 9999;
  overflow: auto;
}

.popup-outer .popup-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: table;
  vertical-align: middle;
  padding: 30px 15px;
}

.popup-outer .popup-box .popup-container {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.popup-outer .confirmation-popup {
  position: relative;
  max-width: 830px;
  padding: 50px 50px;
  background: #ffffff;
  margin: 0 auto;
  border-radius: 3px;
}

.popup-outer .confirmation-popup .close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
}

.popup-outer .confirmation-popup .close-btn a {
  position: relative;
  display: block;
  color: #d0d0d0;
  font-size: 16px;
  line-height: 30px;
  width: 30px;
  height: 30px;
}

.popup-outer .confirmation-popup .close-btn a:hover {
  color: #ff8201;
}

.confirmation-popup .image-column {
  position: relative;
  margin-bottom: 20px;
}

.confirmation-popup .image-box {
  position: relative;
  display: block;
  text-align: center;
}

.confirmation-popup .image-box img {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.confirmation-popup h3 {
  font-weight: 500;
  color: #222222;
  font-size: 18px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.confirmation-popup h3 a {
  color: #222222;
}

.confirmation-popup h3 a:hover {
  color: #ff8201;
}

.confirmation-popup .content-column {
  position: relative;
}

.confirmation-popup .content-column .text {
  position: relative;
  margin-bottom: 20px;
  line-height: 1.8em;
  font-size: 14px;
}

.confirmation-popup .content-column .info {
  position: relative;
  border: 1px solid #d0d0d0;
  margin-bottom: 20px;
  color: #222222;
  text-align: center;
  border-radius: 3px;
}

.confirmation-popup .content-column .info li {
  position: relative;
  float: left;
  width: 50%;
  line-height: 24px;
  padding: 8px 25px;
  border-right: 1px solid #d0d0d0;
}

.confirmation-popup .content-column .info .price {
  color: #ff8201;
}

.confirmation-popup .content-column .info .price strong {
  font-family: "Catamaran", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.confirmation-popup .content-column .info li:nth-child(2n + 2) {
  border-right: none;
}

.confirmation-popup .content-column .count {
  position: relative;
  font-family: "Catamaran", sans-serif;
  font-size: 15px;
  color: #222222;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 40px;
}

.confirmation-popup .content-column .count .value {
  color: #ff8201;
  font-size: 16px;
}

.confirmation-popup .message-outer {
  position: relative;
  text-align: center;
}

.confirmation-popup .message {
  position: relative;
  display: inline-block;
  font-family: "Catamaran", sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 40px;
  line-height: 22px;
  background: #ff8201;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 3px;
}

.confirmation-popup .message .fa {
  padding-right: 5px;
  color: #ffffff;
}

.bg-lightgrey {
  background-color: #f7f7f7;
}

.no-bg {
  background: none;
}

.text-uppercase {
  text-transform: uppercase;
}

textarea {
  overflow: hidden;
}

button {
  outline: none !important;
  cursor: pointer;
}

.text {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #555555;
  margin: 0px 0px 15px;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
  border-radius: 0;
}

.bootstrap-select .dropdown-menu li a span.text {
  margin-bottom: 0;
}

.bootstrap-select .dropdown-menu li.active a span.text {
  color: #fff;
}

.bootstrap-select .dropdown-toggle .filter-option:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f107";
  position: absolute;
  right: 15px;
  top: 7px;
  display: block;
  line-height: 30px;
  font-size: 17px;
  text-align: center;
  z-index: 5;
  font-weight: 400;
  color: #fff;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  z-index: 9;
  overflow: hidden;
}

.auto-container {
  position: static;
  max-width: 1200px;
  /* padding: 0px 15px; */
  margin: 0 auto;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

figure {
  margin-bottom: 0;
}

.owl-carousel img {
  width: auto !important;
  display: inline-block !important;
}

.theme-btn {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.centered {
  text-align: center !important;
}

.gray-bg {
  background-color: #f4f4f4 !important;
}

.light-bg {
  background-color: #fff !important;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.dropdown-toggle::after {
  display: none;
}
.fa {
  line-height: inherit;
}
.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #fff;
}
.owl-nav,
.owl-dots {
  display: none;
}
.row {
  margin: 0 -15px;
}
.row > * {
  padding-left: 15px;
  padding-right: 15px;
}
.row.no-gutters {
  margin: 0;
}
.row.no-gutters > * {
  padding-left: 0;
  padding-right: 0;
}

.opacity_0 {
  opacity: 0;
}
.opacity_1 {
  opacity: 1;
}
.opacity_10 {
  opacity: 0.1;
}
.opacity_20 {
  opacity: 0.2;
}
.opacity_30 {
  opacity: 0.3;
}
.opacity_40 {
  opacity: 0.4;
}
.opacity_50 {
  opacity: 0.5;
}
.opacity_60 {
  opacity: 0.6;
}
.opacity_70 {
  opacity: 0.7;
}
.opacity_80 {
  opacity: 0.8;
}
.opacity_90 {
  opacity: 0.9;
}
.tx_50 {
  transform: translateX(50%);
}
.tx__50 {
  transform: translateX(-50%);
}
.ty_50 {
  transform: translateY(50%);
}
.ty__50 {
  transform: translateY(-50%);
}

/* Btn style */

.theme-btn {
  display: inline-block;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

/*  Scroll To Top style */

.scroll-to-top {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 65px;
  height: 65px;
  font-size: 18px;
  line-height: 65px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;
  margin-left: -26px;
  display: none;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  background: var(--theme-color);
  color: #fff;
}

.scroll-to-top.style-two {
  background-color: #2d3247;
}

.scroll-to-top:hover {
  color: #ffffff;
}

/*Btn Style One*/

.btn-style-one {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 18px 50px;
  overflow: hidden;
  text-transform: uppercase;
  vertical-align: middle;
  border-radius: 30px;
  background-color: var(--theme-color);
  font-size: 14px;
  letter-spacing: 1px;
  color: #fefefe;
  font-weight: 700;
  transition: 0.5s;
  z-index: 0;
}

.btn-style-one:hover {
  color: #fff;
}

.btn-style-one:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  content: "";
  background-color: #000;
  transition: 0.5s;
  opacity: 0.1;
  border-top-right-radius: 33px;
  border-bottom-right-radius: 33px;
  z-index: -1;
}

.btn-style-one:hover:before {
  width: 100%;
  border-radius: 0;
}

/* Style Two */

.btn-style-two {
  position: relative;
  display: inline-block;
  padding: 12px 40px;
  overflow: hidden;
  vertical-align: middle;
  letter-spacing: 1px;
  transition: 0.5s;
  z-index: 1;
  border-radius: 10px;
  background-color: #f0f0f0;
  font-size: 18px;
  line-height: 34px;
  color: #212121;
  font-weight: 400;
}

.btn-style-two:hover {
  color: #fff;
}

.btn-style-two:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  content: "";
  background-color: var(--theme-color);
  transition: 0.5s;
  border-top-right-radius: 33px;
  border-bottom-right-radius: 33px;
  z-index: -1;
}

.btn-style-two:hover:before {
  width: 100%;
  border-radius: 0;
}

/* Header Style */

.main-header {
  position: relative;
  display: block;
  width: 100%;
  z-index: 9999;
  top: 0px;
  left: 0px;
  background: none;
  clear: both;
}

.main-header.header-style-one .auto-container {
  max-width: 1360px;
}

.main-header.header-style-one {
  position: absolute;
}

.main-header.header-style-one .main-menu .navigation > li > a {
  color: #fff;
}

.main-header.header-style-one .header-upper .search-toggler {
  color: #fff;
}

.main-header.header-style-one.fixed-header .sticky-header {
  background-color: #00090e;
}

/* Header Top */
.header-top {
  position: relative;
  background-color: #343434;
  padding: 7.5px 0;
  border-top: 3px solid var(--theme-color);
}

.header-top .inner-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.header-top .left-column {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header-top ul.contact-info {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header-top ul.contact-info li {
  margin-right: 22px;
  color: #b3b3b3;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  padding-right: 20px;
}

.header-top ul.contact-info li:before {
  position: absolute;
  content: "";
  top: 1px;
  right: 0;
  width: 1px;
  height: 20px;
}

.header-top ul.contact-info li:last-child:before {
  display: none;
}

.header-top ul.contact-info a {
  color: #b3b3b3;
  transition: 0.5s;
}

.header-top ul.contact-info a:hover {
  color: var(--theme-color);
}

.header-top ul.contact-info li i {
  margin-right: 7px;
  font-size: 15px;
  vertical-align: middle;
  position: relative;
  top: 3px;
  color: var(--theme-color);
}

.header-top .social-icon {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header-top .social-icon li {
  margin-right: 20px;
  font-size: 14px;
  line-height: 20px;
}

.header-top .social-icon li a {
  color: #b3b3b3;
  transition: 0.5s;
}

/* Header Upper */

.main-header .header-upper .navbar-right {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 50px;
  border-left: 1px solid #fff;
  padding-left: 25px;
}

.main-header .header-upper {
  position: relative;
}

.main-header .header-upper .inner-container {
  position: relative;
  min-height: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.main-header .header-upper .logo-box {
  z-index: 10;
}

.main-header .header-upper .logo-box .logo {
  position: relative;
  display: block;
  padding: 23px 0px;
}

.main-header .header-upper .right-column {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.main-header .header-upper .search-btn {
  margin-left: 35px;
  padding-left: 20px;
  line-height: 20px;
  border-left: 1px solid #919191;
  margin-right: 20px;
}

.main-header .header-upper .link-btn a {
  vertical-align: middle;
}

.main-header .header-upper .search-toggler {
  color: #212121;
  background: transparent;
  font-size: 22px;
}

.main-header .nav-outer {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.navbar-right-info .mobile-nav-toggler {
  position: relative;
  width: 50px;
  height: 50px;
  line-height: 44px;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 20px;
  margin-left: 30px;
  cursor: pointer;
  border-radius: 50%;
}

.main-header .nav-outer .main-menu {
  position: relative;
}

.main-menu .inner-container {
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: -25px;
  background: #fff;
}

.main-menu .navbar-collapse {
  padding: 0px;
  display: block !important;
}

.main-menu .navigation {
  position: relative;
  margin: 0px;
  margin-left: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.main-menu .navigation > li {
  position: relative;
  padding: 38.5px 0px;
  margin-right: 35px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li.current:before {
  opacity: 1;
}

.main-menu .navigation > li.current > a:before {
  opacity: 1;
}

.main-menu .navigation > li:last-child {
  margin-right: 0px;
}

.main-menu .navigation > li > a {
  position: relative;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-transform: capitalize;
  color: #212121;
  opacity: 1;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li > ul {
  position: absolute;
  left: 0px;
  top: 100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: 200px;
  z-index: 100;
  display: none;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  transition: 0.5s;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation > li > ul > li {
  position: relative;
  width: 100%;
  padding: 0 20px;
}

.main-menu .navigation > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li:before {
  position: absolute;
  content: "";
  right: 0px;
  top: 0px;
  width: 0%;
  height: 100%;
  display: block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.main-menu .navigation > li > ul > li > a {
  position: relative;
  display: block;
  padding: 14px 0px;
  border-bottom: 1px solid #e9e5df;
  line-height: 24px;
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
  color: #2a2a2a;
  text-align: left;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li:last-child > a {
  border-bottom: 0px;
}

.main-menu .navigation > li > ul > li.dropdown > a:after {
  position: absolute;
  content: "\f105";
  right: 0;
  top: 11px;
  display: block;
  line-height: 24px;
  font-size: 17px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  left: 100%;
  top: 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: 200px;
  z-index: 100;
  display: none;
  background-color: #fff;
  transition: 0.5s;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}

.main-menu .navigation > li > ul > li > ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  width: 100%;
  padding: 0 20px;
}

.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 0%;
  height: 100%;
  display: block;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  padding: 14px 0;
  line-height: 24px;
  font-weight: 600;
  border-bottom: 1px solid #e9e5df;
  font-size: 15px;
  text-transform: capitalize;
  color: #2a2a2a;
  text-align: left;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li:last-child > a {
  border-bottom: 0;
}

.main-menu .navigation > li > ul > li > ul > li.dropdown > a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f105";
  position: absolute;
  right: 30px;
  top: 12px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  z-index: 5;
}

.main-menu .navigation > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.main-menu .navigation li > ul > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 34px;
  height: 30px;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.main-header .header-upper .contact-info {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 30px;
  padding-right: 30px;
  border-right: 1px solid #eee;
  margin-left: 20px;
}

.main-header .header-upper .contact-info .icon {
  position: relative;
  font-size: 24px;
  text-align: center;
  line-height: 68px;
  margin-right: 15px;
  color: var(--theme-color);
  transition: 0.5s;
  width: 62px;
  height: 62px;
  border-radius: 31px;
  background-color: #f0f0f0;
}

.main-header .header-upper .contact-info:hover .icon {
  color: #fff;
  background-color: var(--theme-color);
}

.main-header .header-upper .contact-info .icon:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.main-header .header-upper .contact-info:hover .icon:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.main-header .header-upper .contact-info .icon span {
  position: relative;
}

.main-header .header-upper .contact-info h5 {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 22px;
  color: #727272;
  font-weight: 400;
}

.main-header .header-upper .contact-info h4 {
  font-size: 16px;
  line-height: 22px;
  color: #212121;
  font-weight: 500;
}

.main-header .header-upper .contact-info h4 a {
  color: #222;
}

/*** 

====================================================================
    Search Popup
====================================================================

***/

.search-popup {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.9);
  -webkit-transform: translateY(101%);
  -ms-transform: translateY(101%);
  transform: translateY(101%);
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
}

.search-popup.popup-visible {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  visibility: visible;
  opacity: 1;
}

.search-popup .overlay-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
}

.search-popup .close-search {
  position: absolute;
  right: 25px;
  top: 25px;
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
}

.search-popup .close-search:hover {
  opacity: 0.7;
}

.search-popup .search-form {
  position: relative;
  padding: 0px 15px 0px;
  max-width: 1024px;
  margin: 0 auto;
  margin-top: 150px;
  margin-bottom: 100px;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.search-popup .search-form fieldset {
  position: relative;
  border: 7px solid rgba(255, 255, 255, 0.5);
  border-radius: 12px;
}

.search-popup .search-form fieldset input[type="search"] {
  position: relative;
  height: 70px;
  padding: 20px 220px 20px 30px;
  background: #ffffff;
  line-height: 30px;
  font-size: 24px;
  color: #233145;
  border-radius: 7px;
}

.search-popup .search-form fieldset input[type="submit"] {
  position: absolute;
  display: block;
  right: 0px;
  top: 0px;
  text-align: center;
  width: 220px;
  height: 70px;
  padding: 20px 10px 20px 10px;
  color: #ffffff !important;
  line-height: 30px;
  font-size: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 0px 7px 7px 0px;
  background-color: var(--theme-color);
}

.search-popup h3 {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-align: center;
  display: none;
}

.search-popup .recent-searches {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  display: none;
}

.search-popup .recent-searches li {
  display: inline-block;
  margin: 0px 10px 10px 0px;
}

.search-popup .recent-searches li a {
  display: block;
  line-height: 24px;
  border: 1px solid #ffffff;
  padding: 7px 15px;
  color: #ffffff;
  border-radius: 3px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.search-popup .search-form fieldset input[type="search"]:focus {
  border-color: #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*** 

====================================================================
                Sticky Header
====================================================================

***/

.sticky-header {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  z-index: -1;
  background: #ffffff;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -webkit-transition: top 300ms ease;
  -o-transition: top 300ms ease;
  transition: top 300ms ease;
}

.fixed-header .sticky-header {
  opacity: 1;
  z-index: 99901;
  visibility: visible;
  background: #fff;
}

/*** 

====================================================================
            Mobile Menu
====================================================================

***/

.nav-outer .mobile-nav-toggler {
  position: relative;
  width: 50px;
  height: 50px;
  line-height: 44px;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  float: right;
  margin: 13px 0;
  margin-left: 15px;
  display: none;
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
}

.mobile-menu .mCSB_scrollTools {
  right: -6px;
}

.mobile-menu .mCSB_inside > .mCSB_container {
  margin-right: 5px;
}

.mobile-menu .navbar-collapse {
  display: block !important;
}

.mobile-menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
  margin-bottom: 100px;
  margin-top: 25px;
}

.mobile-menu-visible {
  overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
}

.mobile-menu .menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  background-color: #000;
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
  opacity: 0.3;
  visibility: visible;
  -webkit-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #202020;
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .close-btn {
  position: absolute;
  right: 20px;
  top: 15px;
  line-height: 30px;
  width: 24px;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
}

.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu .navigation:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li ul li > a {
  font-size: 15px;
  margin-left: 20px;
  text-transform: capitalize;
}

.mobile-menu .navigation li > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li.current > a:before {
  height: 100%;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 6px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn.open {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
  display: none;
}

.mobile-menu .social-links {
  position: relative;
  text-align: center;
  padding: 30px 25px;
}

.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 10px 10px;
}

.mobile-menu .social-links li a {
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

/* Hidden Bar */

.hidden-sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 99999;
  width: 100%;
  max-width: 446px;
  height: 100%;
  overflow: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  direction: rtl;
}

.hidden-sidebar-close {
  position: absolute;
  bottom: 0;
  right: -70px;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 70px;
}

.hidden-sidebar .logo {
  margin-bottom: 35px;
}

.hidden-sidebar .wrapper-box {
  height: 100%;
}

.hidden-sidebar .content-wrapper {
  padding: 80px 30px;
  margin-right: 70px;
  direction: ltr;
  position: relative;
}

.hidden-sidebar .sidebar-widget {
  margin-bottom: 35px;
  padding: 0;
  background-color: transparent;
}

.hidden-sidebar .text-widget .text {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 0;
}

.pdf-widget {
  position: relative;
}

.pdf-widget .row {
  margin: 0 -7.5px;
}

.pdf-widget .column {
  padding: 0 7.5px;
}

.pdf-widget .content {
  background-color: rgb(255 255 255 / 96%);
  text-align: center;
  padding: 30px 10px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.pdf-widget .content .icon {
  margin-bottom: 15px;
}

.pdf-widget .content h4 {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.contact-widget {
  position: relative;
  margin-bottom: 30px;
}

.contact-widget .icon-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.contact-widget .icon {
  width: 35px;
  font-size: 18px;
  margin-top: 5px;
  color: #fff;
}

.contact-widget .text {
  font-size: 17px;
  line-height: 44px;
  font-weight: 400;
  color: #b3b3b3;
}

.contact-widget .text a {
  color: #ffffff;
}

.contact-widget .text strong {
  color: #fff;
  font-weight: 700;
  display: block;
}

.nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;
  width: 100%;
  display: none;
  background: rgba(20, 20, 20, 0.7);
  overflow: hidden;
  cursor: none;
}

/* Cursor Style */

.cursor {
  position: absolute;
  background-color: #fff;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  z-index: 1;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -o-transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  visibility: hidden;
}

.cursor {
  visibility: visible;
}

.cursor.active {
  opacity: 0.5;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.cursor.hovered {
  opacity: 0.08;
}

.cursor-follower {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  z-index: 1;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -o-transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  visibility: hidden;
}

.cursor-follower {
  visibility: visible;
}

.cursor-follower.active {
  opacity: 0.7;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.cursor-follower.hovered {
  opacity: 0.08;
}

.cursor-follower.close-cursor:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 2px;
  background: #fff;
  left: 48%;
  top: 12px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: inline-block;
}

.cursor-follower.close-cursor:after {
  position: absolute;
  content: "";
  height: 25px;
  width: 2px;
  background: #fff;
  right: 48%;
  top: 12px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*** 

====================================================================
        Banner Section
====================================================================

***/

.banner-section {
  position: relative;
}

.banner-section .background-text {
  position: absolute;
  right: -280px;
  bottom: 190px;
  z-index: 99;
  text-transform: uppercase;
  font-size: 240px;
  font-weight: 700;
  letter-spacing: -5px;
  line-height: 200px;
  color: rgb(255 255 255 / 0.1);
  transform: rotate(-30deg);
}

.banner-section .background-text .text-2 {
  position: absolute;
  top: -5px;
  left: 15px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255 255 255 / 0.2);
  color: transparent;
}

.three-item-carousel,
.banner-slider {
  position: relative;
  height: 100% !important;
  z-index: 9;
}

.banner-section .swiper-slide {
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner-section .swiper-slide:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000000;
  opacity: 0;
  content: "";
}

.banner-section .content-outer {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: table;
  vertical-align: middle;
}

.banner-section .content-box {
  position: relative;
  padding: 120px 15px 10px;
  min-height: 900px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

.banner-section .content-box .inner {
  position: relative;
  opacity: 0;
  -webkit-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
  padding: 0 15px;
}

.banner-section .swiper-slide-active .content-box .inner {
  opacity: 1;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.banner-section .content-box h1 {
  position: relative;
  font-size: 80px;
  font-weight: 900;
  line-height: 90px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  margin-bottom: 30px;
  padding-bottom: 30px;
  letter-spacing: 0;
}

.banner-section .content-box h1:before {
  position: absolute;
  content: "";
  height: 3px;
  width: 76px;
  bottom: 0;
  left: 0;
}

.banner-section .content-box.justify-content-center h1:before {
  left: 50%;
  transform: translateX(-50%);
}

.banner-section .swiper-slide-active .content-box h1 {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 800ms;
  -o-transition-delay: 800ms;
  transition-delay: 800ms;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.banner-section .content-box h4 {
  position: relative;
  display: block;
  line-height: 1.2em;
  margin-bottom: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  font-size: 24px;
  color: var(--theme-color);
  font-weight: 400;
}

.banner-section .swiper-slide-active h4 {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 1200ms;
  -o-transition-delay: 1200ms;
  transition-delay: 1200ms;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.banner-section .content-box .text {
  position: relative;
  font-size: 24px;
  font-weight: 300;
  line-height: 34px;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  transform: translateX(-50px);
  margin-bottom: 40px;
}

.banner-section .swiper-slide-active .text {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 1600ms;
  -o-transition-delay: 1600ms;
  transition-delay: 1600ms;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.banner-section .link-box {
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transition-delay: 2000ms;
  -o-transition-delay: 2000ms;
  transition-delay: 2000ms;
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  margin: 0 -10px;
}

.banner-section .swiper-slide-active .link-box {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.banner-section .link-box a {
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  margin: 0 10px 10px;
}

.banner-section .banner-slider-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.banner-section .banner-slider-button-next {
  position: relative;
  width: 70px;
  height: 70px;
  line-height: 76px;
  text-align: center;
  color: #181614;
  cursor: pointer;
  z-index: 9;
  margin-right: 50px;
  font-size: 20px;
  background-color: rgb(255 255 255);
  transition: 0.5s;
  border-radius: 50%;
}

.banner-section .banner-slider-button-next:hover {
  color: #fff;
  background: var(--theme-color);
}

.banner-section .banner-slider-button-prev {
  position: relative;
  width: 70px;
  height: 70px;
  line-height: 76px;
  text-align: center;
  color: #181614;
  cursor: pointer;
  z-index: 9;
  margin-left: 50px;
  font-size: 20px;
  background-color: rgb(255 255 255 / 98%);
  transition: 0.5s;
  transform: rotate(180deg);
  border-radius: 50%;
}

.banner-section .banner-slider-button-prev:hover {
  color: #fff;
  background: var(--theme-color);
}

/* Style Two */
.banner-section .banner-slider-nav.style-two {
  left: 0;
  right: auto;
  top: auto;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-prev {
  width: 106px;
  height: 53px;
  border-radius: 0;
  margin: 0;
  line-height: 53px;
  background: #293e9c;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-next {
  height: 53px;
  width: 106px;
  border-radius: 0;
  margin: 0;
  line-height: 53px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-next i {
  margin-left: 10px;
  position: relative;
  top: 1px;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-prev i {
  margin-right: 10px;
  top: 1px;
  position: relative;
}

/* Sec Title */
.sec-title {
  position: relative;
  margin-bottom: 50px;
}

.sec-title h4 {
  position: relative;
  font-size: 18px;
  color: #737373;
  font-weight: 400;
  padding-top: 20px;
  margin-bottom: 10px;
}

.sec-title.light h4 {
  color: #fff;
}

.sec-title h4:before {
  position: absolute;
  content: "";
  width: 21px;
  height: 3px;
  border-radius: 2px;
  background-color: var(--theme-color);
  top: 0;
  left: 0;
}

.sec-title.text-center h4:before {
  left: 50%;
  transform: translateX(-50%);
}

.sec-title h2 {
  position: relative;
  font-size: 46px;
  color: #212121;
  font-weight: 500;
}

.sec-title.light h2 {
  color: #fff;
}

.sec-title .text {
  margin-top: 16px;
  margin-bottom: 0px;
}

.sec-title.light .text {
  color: #fff;
}

section.about-section {
  position: relative;
  padding: 66px 0 66px 0px;
}

@media screen and (max-width: 600px) {
  section.about-section {
    padding: 15px 11px 15px 11px;
  }
}

.about-section .sec-title {
  margin-bottom: 30px;
}

.about-section h3 {
  font-size: 24px;
  line-height: 38px;
  color: var(--theme-color);
  font-weight: 400;
  margin-bottom: 20px;
}

.about-section .text {
  color: #737373;
  margin-bottom: 30px;
}

.about-section .icon_box {
  position: relative;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.about-section .icon_box i {
  width: 90px;
  height: 90px;
  border-radius: 45px;
  background-color: var(--theme-color);
  text-align: center;
  line-height: 105px;
  color: #fff;
  font-size: 50px;
  margin-right: 20px;
  transition: 0.5s;
}

.about-section .icon_box:hover i {
  background-color: #222;
}

.about-section .icon_box h4 {
  font-size: 20px;
  line-height: 30px;
  color: #212121;
  font-weight: 500;
}

.about-section .phone-number {
  position: relative;
  border-top: 1px solid #dbdbdb;
  padding-top: 40px;
  font-size: 20px;
  line-height: 34px;
  color: #868686;
  font-weight: 400;
}

.about-section .phone-number a {
  color: #212121;
  font-size: 30px;
}

.about-section .image {
  position: relative;
  margin-bottom: 30px;
  text-align: right;
}

.about-section .image_box {
  position: absolute;
  left: 0;
  bottom: 40px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  text-align: left;
  border-radius: 10px;
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.07));
  background-color: #ffffff;
  z-index: 1;
}

@media only screen and (min-width: 992px) {
  .about-section .image_box {
    left: -30px;
  }
  .about-section .image_box:before {
    position: absolute;
    content: "";
    width: 60px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--theme-color);
    left: 0;
    bottom: -40px;
  }
}

.about-section .image_box img {
  margin-right: 25px;
}

.about-section .image_box h4 {
  font-size: 26px;
  line-height: 32px;
  color: #212121;
  font-weight: 400;
}

section.funfacts-section {
  position: relative;
  padding: 80px 0 50px;
}

section.funfacts-section:before {
  position: absolute;
  content: "";
  max-width: 1570px;
  width: 100%;
  height: 785px;
  border-radius: 30px;
  background-color: #f0f0f0;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
}

.funfact-block-one {
  position: relative;
  margin-bottom: 30px;
}

.funfact-block-one:before {
  position: absolute;
  content: "";
  top: 0;
  right: 50px;
  bottom: 0;
  left: 15px;
  transform: scaleY(0);
  transition: 0.7s;
  background-color: #fff;
  border-radius: 10px;
  transform-origin: top;
}
.funfact-block-one:hover:before {
  transform: scale(1);
}

.funfact-block-one .inner-box {
  position: relative;
  max-width: 160px;
  margin: 0 auto;
  padding: 40px 0;
}

.funfact-block-one .icon {
  font-size: 60px;
  color: var(--theme-color);
  margin-bottom: 30px;
}

.funfact-block-one .count-outer.count-box {
  font-size: 40px;
  line-height: 36px;
  color: #212121;
  font-weight: 400;
  margin-bottom: 7px;
}

.funfact-block-one .text {
  font-size: 17px;
  color: #737373;
  margin: 0;
}

.funfact-block-one:after {
  position: absolute;
  content: "";
  top: 40px;
  right: 18px;
  bottom: 40px;
  width: 1px;
  background-color: #dbdbdb;
}

.funfact-block-one:last-child:after {
  display: none;
}

/* courses section */
section.courses-section {
  position: relative;
  padding: 120px 0 90px;
}

.course-block-one .inner-box {
  border-radius: 10px;
  background-color: #ffffff;
  text-align: center;
  margin-bottom: 30px;
}

.course-block-one .image {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.course-block-one .image:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-color);
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: perspective(400px) rotateX(-90deg);
  -ms-transform: perspective(400px) rotateX(-90deg);
  transform: perspective(400px) rotateX(-90deg);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  z-index: 1;
}
.course-block-one .inner-box:hover .image:before {
  opacity: 0.85;
  -webkit-transform: perspective(400px) rotateX(0deg);
  -ms-transform: perspective(400px) rotateX(0deg);
  transform: perspective(400px) rotateX(0deg);
}

.course-block-one .image img {
  transition: 0.5s;
  width: 100%;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.course-block-one .instructor-thumb img {
  border-radius: 37.5px;
  background-color: #f0f0f0;
  border: 3px solid #ffffff;
  width: 75px;
  height: 75px;
}

.course-block-one .instructor-thumb {
  position: relative;
  transform: translateY(-50%);
  margin-bottom: -20px;
  z-index: 9;
}

.course-block-one .designation {
  font-size: 18px;
  line-height: 24px;
  color: #737373;
  font-weight: 400;
}

.course-block-one .name {
  font-size: 18px;
  line-height: 24px;
  color: var(--theme-color);
  font-weight: 500;
  margin-bottom: 20px;
}

.course-block-one h3 {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 34px;
  color: #212121;
  font-weight: 400;
}

.course-block-one h3 a {
  color: #212121;
  transition: 0.5s;
}

.course-block-one h3 a:hover {
  color: var(--theme-color);
}

.course-block-one .text {
  font-size: 18px;
  line-height: 34px;
  color: #737373;
  font-weight: 400;
  margin-bottom: 25px;
}

.course-block-one .lower-content {
  position: relative;
  margin-bottom: 23px;
  padding: 0 23px;
}

.course-block-one .lower-content {
  position: relative;
  padding: 0 23px;
  padding-bottom: 23px;
  border: 1px solid #dcdcdc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
}

.course-block-one .btn-style-two {
  width: 100%;
}

.course-block-one .btn-style-two span {
  color: var(--theme-color);
  transition: 0.5s;
}

.course-block-one .btn-style-two:hover span {
  color: #fff;
}

/* services section */
section.service-section {
  position: relative;
  padding: 120px 0 90px;
  background-size: cover;
}

section.service-section:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #212121;
  opacity: 0.9;
}

section.service-section .sec-title.light h4 {
  color: #b3b3b3;
}

.video-box {
  position: relative;
  width: 300px;
  height: 352px;
  border-radius: 10px;
  background-color: var(--theme-color);
  padding: 55px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.video-box .video-btn a {
  color: #fff;
  font-size: 65px;
  margin-bottom: 30px;
  display: inline-block;
}

.video-box h4 {
  font-size: 34px;
  line-height: 46px;
  color: #ffffff;
  font-weight: 400;
}

.video-box {
  margin-top: -120px;
}

.service-block-1 {
  position: relative;
  margin-bottom: 30px;
}

.service-block-1 .icon_box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}

.service-block-1 .icon {
  width: 90px;
  height: 90px;
  border-radius: 45px;
  background-color: var(--theme-color);
  margin-right: 20px;
  color: #fff;
  text-align: center;
  line-height: 105px;
  font-size: 40px;
  transition: 0.5s;
}

.service-block-1 .icon_box:hover .icon {
  background-color: #fff;
  color: var(--theme-color);
}

.service-block-1 h4 {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 500;
}

.service-block-1 .text {
  width: 325px;
  height: 79px;
  font-size: 17px;
  line-height: 32px;
  color: #b3b3b3;
  font-weight: 400;
}

section.service-section .divider {
  position: relative;
  height: 1px;
  background: rgb(255 255 255 / 20%);
  margin-top: 60px;
  margin-bottom: 85px;
  border: transparent;
}

/* Experience section */

section.experience-section {
  position: relative;
  padding: 120px 0 0;
}

section.experience-section .cta {
  position: relative;
  text-align: center;
}

section.experience-section .cta h2 {
  font-size: 40px;
  line-height: 40px;
  color: #ffffff;
  font-weight: 900;
  margin-bottom: 15px;
}

section.experience-section .cta .text {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

section.experience-section .cta .content {
  position: relative;
  padding: 70px 0;
  background-size: cover;
  border-radius: 10px;
  margin-bottom: 120px;
}

section.experience-section .shape-one {
  position: absolute;
  left: 0;
  bottom: 0;
}

section.experience-section .image {
  position: relative;
  margin-top: -60px;
  animation-fill-mode: inherit;
}

section.experience-section .image img {
  mix-blend-mode: multiply;
  max-width: none;
  float: right;
}

section.experience-section .image .text {
  position: absolute;
  width: 190px;
  height: 190px;
  border-radius: 95px;
  filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.07));
  background-color: #ffffff;
  top: 35%;
  left: -212px;
  text-align: center;
  padding-top: 50px;
  font-size: 24px;
  line-height: 30px;
  color: #212121;
  font-weight: 400;
  font-style: italic;
}

section.experience-section .text-two {
  position: relative;
  margin-bottom: 30px;
}

section.experience-section .text-box {
  margin-bottom: 30px;
}

section.experience-section .text-box h4 {
  font-size: 20px;
  line-height: 30px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 10px;
}

section.experience-section .text-box h4 i {
  margin-right: 5px;
  color: var(--theme-color);
}

/*=======progress bar========*/

.progress-levels {
  position: relative;
}

.progress-levels h5 {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 40px;
  color: #212121;
  font-weight: 500;
}

.progress-levels .progress-box {
  position: relative;
  padding-bottom: 35px;
  padding-top: 10px;
  overflow: hidden;
}

.progress-levels .progress-box:last-child {
  margin-bottom: 0px;
}

.progress-levels .progress-box .bar {
  position: relative;
  height: 13px;
  border-radius: 7px;
  background-color: #f0f0f0;
}

.progress-levels .progress-box .bar .bar-innner {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 13px;
  border-radius: 6px;
}

.progress-levels .progress-box .bar .bar-fill {
  left: -100%;
  top: 0px;
  width: 0px;
  height: 13px;
  -webkit-transition: all 2000ms ease 300ms;
  -o-transition: all 2000ms ease 300ms;
  transition: all 2000ms ease 300ms;
  background: var(--theme-color);
  border-radius: 7px;
}

.progress-levels .progress-box.html .bar .bar-fill {
  background: #ef6267;
}

.progress-levels .progress-box.wp .bar .bar-fill {
  background: #ff9924;
}

.progress-levels .progress-box.php .bar .bar-fill {
  background: #14dbba;
}

.progress-levels .progress-box.animated .bar .bar-fill {
  left: 0px;
}

.progress-levels .progress-box .percent {
  position: absolute;
  right: 0;
  top: -45px;
  letter-spacing: 0px;
  opacity: 0;
  -webkit-transition: all 2000ms ease 700ms;
  -o-transition: all 2000ms ease 700ms;
  transition: all 2000ms ease 700ms;
  font-size: 16px;
  line-height: 40px;
  color: #737373;
  font-weight: 400;
}

.progress-levels .progress-box.animated .percent {
  opacity: 1;
}

/* testimonials section */

section.testimonials-section {
  position: relative;
  padding: 50px 0 90px;
}

.testimonials-block-one {
  position: relative;
  margin-bottom: 30px;
}

.testimonials-block-one .inner-box {
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  position: relative;
  padding: 60px;

  transition: 0.5s;
}

.testimonials-block-one .inner-box:hover {
  border-color: #fff;
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.05));
}

.testimonials-block-one .inner-box:before {
  position: absolute;
  content: "";
  left: 35px;
  bottom: 0;
  right: 35px;
  background-color: var(--theme-color);
  transition: 0.5s;
  height: 50px;
  border-radius: 10px;
  opacity: 0;
}

.testimonials-block-one .inner-box:hover:before {
  opacity: 1;
  bottom: -10px;
}

.testimonials-block-one .inner-box:after {
  position: absolute;
  content: "";
  left: 30px;
  bottom: 0;
  right: 30px;
  background-color: #fff;
  transition: 0.5s;
  height: 50px;
}

.testimonials-block-one .text {
  font-size: 18px;
  line-height: 36px;
  color: #737373;
  font-weight: 400;
  margin-bottom: 30px;
}

.testimonials-block-one .author-info {
  position: relative;
  padding: 12px 0;
  padding-left: 92px;
}

.testimonials-block-one .image {
  position: absolute;
  top: 0;
  left: 0;
}

.testimonials-block-one .name {
  font-size: 18px;
  line-height: 24px;
  color: #212121;
  font-weight: 500;
}

.testimonials-block-one .designation {
  font-size: 14px;
  line-height: 24px;
  color: var(--theme-color);
  font-weight: 400;
}

@media screen and (max-width: 600px) {
  section.testimonials-section {
    position: relative;
    padding: 20px 0 0px;
  }
}
/* Appointment section */

section.appointment-section {
  position: relative;
  padding: 120px 0 0;
  background-size: cover;
}

section.appointment-section:before {
  position: absolute;
  content: "";
  background: var(--theme-color);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.92;
}

section.appointment-section .sec-title h4:before {
  background: #fff;
}

section.appointment-section .text {
  position: relative;
  color: #fff;
  margin-bottom: 30px;
}

section.appointment-section .icon_box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

section.appointment-section .icon {
  position: relative;
  color: #fff;
  font-size: 60px;
  margin-right: 20px;
  margin-bottom: 15px;
}

section.appointment-section h4 {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 15px;
}

section.appointment-section .contact-form {
  position: relative;
  background: #fff;
  padding: 60px;
  margin-left: 100px;
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.1));
  background-color: #ffffff;
  border-radius: 10px;
  border-top-left-radius: 0;
}

.contact-form input {
  height: 63px;
  border-radius: 10px;
  background-color: #f0f0f0;
  width: 100%;
  padding: 0 25px;
  font-size: 15px;
  color: #737373;
  font-weight: 400;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form textarea {
  height: 177px;
  border-radius: 10px;
  background-color: #f0f0f0;
  width: 100%;
  padding: 13px 25px;
  font-size: 15px;
  color: #737373;
  font-weight: 400;
}

.contact-form.style-two textarea,
.contact-form.style-two input {
  border-color: #f0f0f0;
}

@media only screen and (min-width: 992px) {
  section.appointment-section .contact-form {
    margin-top: -60px;
    margin-bottom: -85px;
  }
}

section.contact-form-section {
  position: relative;
  padding: 120px 0 100px;
}

.video-box-two {
  position: absolute;
  top: 0;
  left: -140px;
  width: 140px;
  height: 133px;
  border-radius: 10px;
  background-color: #212121;
  text-align: center;
  color: #fff;
  font-size: 30px;
  line-height: 133px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.video-box-two a {
  color: #fff;
  transition: 0.5s;
}

.video-box-two a:hover {
  color: var(--theme-color);
}

/* Client logo */

section.client-logo {
  position: relative;
  padding: 120px 0;
}

section.client-logo .image {
  text-align: center;
}

.divider {
  position: relative;
  border: 1px solid #dcdcdc;
}

/* Courses section style two */
section.courses-section.style-tweo:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 524px;
  background: #212121;
}

/* Blog section */
section.news-section {
  position: relative;
  padding: 120px 0 90px;
}

.news-block-one {
  position: relative;
}

.news-block-one .image {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.news-block-one .image img {
  transition: 0.5s;
}

.news-block-one .image:before {
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.3))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
  opacity: 0;
}

.news-block-one .inner-box:hover .image:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
  opacity: 1;
}

.news-block-one .inner-box {
  position: relative;
  margin-bottom: 30px;
}

.news-block-one .lower-content {
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 30px;
  padding-bottom: 0;
  position: relative;
}

.news-block-one h4 {
  font-size: 24px;
  line-height: 36px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 20px;
}

.news-block-one h4 a {
  color: #212121;
  transition: 0.5s;
}

.news-block-one h4 a:hover {
  color: var(--theme-color);
}

.news-block-one .text {
  font-size: 17px;
  line-height: 34px;
  color: #737373;
  font-weight: 400;
  margin-bottom: 30px;
}

.news-block-one ul.post-mate {
  border-radius: 10px;
  background-color: #f0f0f0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.news-block-one ul.post-mate li {
  position: relative;
  margin-right: 20px;
  font-size: 14px;
  line-height: 40px;
  color: #737373;
  font-weight: 400;
}

.news-block-one ul.post-mate li i {
  color: var(--theme-color);
  margin-right: 5px;
}

.news-block-one ul.post-mate li:last-child {
  margin-right: 0;
}

/* Courses style two */
section.courses-section.style-two:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  height: 522px;
  background: #212121;
}

/* Main Footer */
footer.main-footer {
  background: #212121;
  position: relative;
  padding: 120px 0 0;
}

footer.main-footer .widgets-section {
  position: relative;
  margin-bottom: 90px;
}

.about-widget {
  position: relative;
  margin-bottom: 30px;
}

.about-widget ul.social-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.about-widget ul.social-links li {
  margin-right: 10px;
  margin-bottom: 10px;
}

.about-widget ul.social-links a {
  width: 52px;
  height: 52px;
  border-radius: 26px;
  background-color: #343434;
  display: inline-block;
  text-align: center;
  line-height: 52px;
  color: #fff;
  transition: 0.5s;
}

.about-widget ul.social-links a:hover {
  background: var(--theme-color);
}

.links-widget {
  position: relative;
}

.links-widget li {
  margin-bottom: 15px;
}

.links-widget a {
  font-size: 17px;
  line-height: 30px;
  color: #b3b3b3;
  font-weight: 400;
}

.text-widget {
  position: relative;
  margin-left: -60px;
}

.text-widget .text {
  position: relative;
  font-size: 17px;
  line-height: 36px;
  color: #b3b3b3;
  font-weight: 400;
}

.contact-widget .text a {
  font-weight: 500;
  color: #fff;
}

.footer-bottom {
  position: relative;
  background-color: #343434;
  border-top: 3px solid var(--theme-color);
  padding-top: 30px;
}

.footer-bottom .wrapper-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.footer-bottom .text {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 30px;
  color: #b3b3b3;
  font-weight: 400;
}
.footer-bottom .footer-menu ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.footer-bottom .logo {
  margin-bottom: 30px;
}

.footer-bottom .footer-menu {
  margin-bottom: 30px;
}

.footer-bottom .footer-menu ul li {
  margin-right: 15px;
}

.footer-bottom .footer-menu ul li:last-child {
  margin-right: 0;
}

.footer-bottom .footer-menu ul li a {
  font-size: 16px;
  line-height: 30px;
  color: #b3b3b3;
  font-weight: 400;
  transition: 0.5s;
}

@media only screen and (max-width: 767px){
  .footer-bottom .text  {
  margin-bottom: 30px;
  font-size: 12px;
  line-height: 30px;
  color: #b3b3b3;
  font-weight: 400;
  }
  .footer-bottom .footer-menu ul li a {
    font-size: 14px;
    line-height: 30px;
    color: #b3b3b3;
    font-weight: 400;
    transition: 0.5s;
  }
}

.footer-bottom .footer-menu ul li a:hover {
  color: var(--theme-color);
}

/* Header style two */

.main-header.header-style-two .auto-container {
  max-width: 1360px;
}

.main-header.header-style-two .header-upper .navbar-right {
  border: none;
  padding: 0;
  margin: 0;
}

section.banner-section.style-two {
  max-width: 1560px;
  margin: 0 auto;
  border-radius: 30px;
  overflow: hidden;
}

.banner-section.style-two .content-box {
  min-height: 665px;
  padding: 0;
  text-align: left;
  max-width: 1360px;
}

.banner-section.style-two .swiper-slide:before {
  opacity: 1;
  width: 50%;
  border-radius: 30px;
  background: rgb(33 33 33 / 90%);
}

.banner-section.style-two .banner-slider-nav {
  left: auto;
  right: 0;
  width: 65px;
  height: 140px;
  top: 50%;
  margin-top: -70px;
  right: 120px;
}

.banner-section.style-two .banner-slider-button-prev {
  margin: 0;
  width: 63px;
  height: 63px;
  border-radius: 32px;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid #ffffff;
  color: #fff;
  line-height: 67px;
  opacity: 0.3;
}

.banner-section.style-two .banner-slider-button-prev:hover {
  opacity: 1;
}

.banner-section.style-two .banner-slider-button-next {
  margin: 0;
  width: 63px;
  height: 63px;
  border-radius: 32px;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid #ffffff;
  line-height: 65px;
  color: #fff;
  opacity: 0.3;
}

.banner-section.style-two .banner-slider-button-next:hover {
  opacity: 1;
}

/* Features section */
section.features-section {
  position: relative;
  padding: 120px 0;
  background: #f0f0f0;
}

.feature-block {
  position: relative;
  margin-bottom: 30px;
}

.feature-block .inner-box {
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 45px;
  padding-left: 140px;
  z-index: 0;
  position: relative;
}

.feature-block:before {
  position: absolute;
  content: "";
  top: 0;
  left: 45px;
  right: 45px;
  bottom: 0;
  transition: 0.5s;
  border-radius: 30px;
  background-color: var(--theme-color);
}

.feature-block:hover:before {
  bottom: -10px;
}

.feature-block .iocn {
  position: absolute;
  top: 50px;
  left: 62px;
  font-size: 72px;
  color: var(--theme-color);
}

.feature-block h4 {
  font-size: 20px;
  line-height: 30px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 5px;
}

.feature-block .text {
  font-size: 15px;
  line-height: 30px;
  color: #737373;
  font-weight: 400;
  margin: 0;
}

section.features-section .shape {
  position: absolute;
  top: -210px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f0f0f0;
}

section.features-section .bottom-text {
  position: relative;
  text-align: center;
  font-size: 17px;
  line-height: 34px;
  color: #737373;
  font-weight: 400;
  padding-bottom: 40px;
  border-bottom: 1px solid #dcdcdc;
  padding-top: 25px;
}

section.features-section .bottom-text a {
  color: #212121;
  font-size: 17px;
  line-height: 44px;
  color: #212121;
  font-weight: 500;
}

.sec-title .rotate-text {
  position: absolute;
  left: -60px;
  top: 0px;
  line-height: 1em;
  color: #f1f1f1;
  font-size: 24px;
  padding-right: 10px;
  font-weight: 900;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
@media screen and (max-width: 600px) {
  .sec-title .rotate-text {
    text-align: center;
  }
}

.sec-title .rotate-text:before {
  position: absolute;
  content: "";
  left: 100%;
  top: 80%;
  width: 1px;
  height: 40px;
  background-color: #ff8201;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/* Introduction */

section.introduction-section {
  position: relative;
  padding: 120px 0 90px;
}

section.introduction-section .image {
  position: relative;
  margin-bottom: 30px;
}

section.introduction-section .image img {
  border-radius: 50%;
}

section.introduction-section .image .img_hover_3 {
  border-radius: 50%;

}

section.introduction-section .image:before {
  position: absolute;
  content: "";
  width: 183px;
  height: 183px;
  border-radius: 91px;
  background-color: var(--theme-color);
}

section.introduction-section .ul {
  margin-bottom: 30px;
}

section.introduction-section .image-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}

section.introduction-section .image-two {
  margin-right: 20px;
  border: 2px solid var(--theme-color);
  padding: 5px;
  border-radius: 50%;
}

section.introduction-section .image-two img {
  border-radius: 50%;
}

section.introduction-section .text-box {
  position: relative;
  border-radius: 10px;
  background-color: #f0f0f0;
  padding: 40px;
  margin-bottom: 30px;
  padding-right: 30px;
  margin-left: -25px;
  padding-bottom: 30px;
}

section.introduction-section .text-box .icon {
  font-size: 25px;
  color: var(--theme-color);
  margin-bottom: 10px;
}

section.introduction-section .text-box .text-two {
  font-size: 17px;
  line-height: 30px;
  color: #737373;
  font-weight: 400;
  margin-bottom: 5px;
}

section.introduction-section .text-box a {
  font-size: 17px;
  line-height: 40px;
  color: #212121;
  font-weight: 500;
}

section.introduction-section .ul li {
  font-size: 18px;
  line-height: 30px;
  color: #212121;
  font-weight: 400;
  margin-bottom: 10px;
  letter-spacing: -0.5px;
}

section.introduction-section .ul li i {
  margin-right: 5px;
  color: var(--theme-color);
  font-size: 14px;
}

/*  */
section.courses-section-two {
  position: relative;
  padding: 120px 0 90px;
}

.course-block-two {
  position: relative;
}

.course-block-two .inner-box {
  position: relative;
  margin-bottom: 30px;
  border-radius: 30px;
  background-color: #212121;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.course-block-two .image {
  position: relative;
  margin-right: 100px;
  overflow: hidden;
}

.course-block-two .image img {
  border-radius: 30px;
}

.course-block-two h4 {
  font-size: 30px;
  color: #ffffff;
  font-weight: 900;
  position: relative;
  margin-bottom: 15px;
  padding-top: 20px;
}

.course-block-two h4 a {
  color: #ffffff;
}

.course-block-two .text {
  font-size: 18px;
  line-height: 34px;
  color: #ffffff;
}

section.courses-section-two .auto-container {
  max-width: 1600px;
}

.course-block-two:last-child .inner-box {
  background: var(--theme-color);
}

.course-block-two .lower-content {
  padding: 50px 0 20px;
}

.course-block-two h4:before {
  position: absolute;
  content: "";
  width: 21px;
  height: 3px;
  border-radius: 2px;
  background-color: var(--theme-color);
  top: 0;
  left: 0;
}

.course-block-two:last-child h4:before {
  background: #fff;
}

/* Team section */

section.our-team-section {
  position: relative;
  padding: 40px 0 10px;
  background: #f0f0f0;
}

section.our-team-section .shape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.our-team-section .auto-container .row{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 600px) {
  section.our-team-section {
    padding: 10px 0 10px;
  }

  .our-team-section .auto-container .row{
    display: block;
  }
  .colno4{
    margin: 0 24%;
  }
}
.team-block-one {
  position: relative;
}

.team-block-one .inner-box {
  position: relative;
  margin-bottom: 30px;
  text-align: center;
}

.team-block-one .image {
  margin-bottom: 30px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  border-radius: 50%;
}

.team-block-one .image img {
  border-radius: 50%;
  transition: 0.5s;
}

.team-block-one .lower-content {
  position: relative;
  padding: 50px 0 30px;
  background-image: url(../images/shape/shape-5.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 190px;
  margin: 0 10px;
  
}

.team-block-one .name {
  font-size: 18px;
  line-height: 20px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 5px;
}

.team-block-one .designation {
  font-size: 13px;
  line-height: 18px;
  color: #737373;
  font-weight: 400;
  margin-bottom: 20px;
}

.team-block-one ul.social-links {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.team-block-one ul.social-links li {
  font-size: 20px;
  line-height: 24px;
  color: #212121;
  font-weight: 500;
  margin: 0 5px;
}

.team-block-one ul.social-links li a {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #f0f0f0;
  display: block;
  line-height: 42px;
  font-size: 14px;
  color: #737373;
  transition: 0.5s;
}

.team-block-one ul.social-links li a:hover {
  color: #fff;
  background: var(--theme-color);
}
@media only screen and (max-width: 768px) {
  .team-block-one .lower-content {
    position: relative;
    padding: 50px 0 30px;
    background-image: url(../images/shape/shape-5.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
  }
  
  .team-block-one .name {
    font-size: 16px;
    line-height: 18px;
    color: #212121;
    font-weight: 500;
    margin-bottom: 2px;
  }
  
  .team-block-one .designation {
    font-size: 12px;
    line-height: 15px;
    color: #737373;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .team-block-one ul.social-links li a {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background-color: #f0f0f0;
    display: block;
    line-height: 32px;
    font-size: 12px;
    color: #737373;
    transition: 0.5s;
  }
}
/* Cta two */

.cta-two {
  position: relative;
  background-image: url(../BookDemo_img.png);
  background-size: cover;
  padding: 120px 0;
  text-align: center;
  /* max-width: 1200px; */
  width: 100vw;
  height: 490px;
}
@media screen and (max-width: 600px) {
  .cta-two {
    height: 156px;
    padding: 5px 0px;
  }
}

.cta-two h4 {
  font-size: 40px;
  color: #ffffff;
  font-weight: 900;
  margin-bottom: 40px;
  line-height: 50px;
}
@media screen and (max-width: 600px) {
  .cta-two h4 {
    font-size: 24px;
    line-height: 29.05px;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 10px;
  }
}
.cta-two .btn-style-one {
  width: 227.8px;
  height: 60px;
  /* padding:15px 41.89px; */
  border-radius: 30px;
  background-color: #fb561e;

  line-height: 24px;
  font-weight: 700;
  font-size: 14px;
  transition: 0.5s;
  z-index: 0;
}
@media screen and (max-width: 600px) {
  .cta-two .btn-style-one {
    width: 119px;
    height: 36px;
    padding: 5px 5px 5px 5px;
  }
}
.cta-two:before {
  position: absolute;
  content: "";
  background: #212121;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.9;
}

/* Pricing Section */

.pricing-section {
  position: relative;
  padding: 120px 0 90px;
}

.pricing-block {
  position: relative;
}

.pricing-block .inner-box {
  position: relative;
  margin-bottom: 30px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  text-align: center;
  padding: 60px 15px;
}

.pricing-block .category {
  position: relative;
  background-color: #f0f0f0;
  padding: 30px 0;
  margin-bottom: 30px;
}

.pricing-block .icon {
  position: relative;
  font-size: 60px;
  color: #f0f0f0;
  margin-bottom: 30px;
}

.pricing-block h2 {
  position: relative;
  font-size: 24px;
  line-height: 30px;
  color: #212121;
  font-weight: 400;
  margin-bottom: 30px;
}

.pricing-block h3 {
  position: relative;
  font-size: 24px;
  line-height: 30px;
  color: #212121;
  font-weight: 400;
  margin-bottom: 30px;
}

.pricing-block .price {
  position: relative;
  font-size: 60px;
  line-height: 70px;
  color: #212121;
  font-weight: 400;
}

.pricing-block ul {
  position: relative;
  margin-bottom: 20px;
}

.pricing-block ul li {
  position: relative;
  font-size: 17px;
  line-height: 44px;
  color: #737373;
  font-weight: 400;
}

.pricing-block .link-btn {
  position: relative;
}

.pricing-block .link-btn .btn-style-two {
  border-radius: 30px;
  color: #737373;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px 50px;
}

.pricing-block .link-btn .btn-style-two:hover {
  color: #fff;
  background-color: var(--theme-color);
}

.pricing-block .category h4 {
  font-size: 20px;
  line-height: 30px;
  color: var(--theme-color);
  font-weight: 400;
  margin-bottom: 5px;
}

.pricing-block.active .icon {
  color: var(--theme-color);
}

.pricing-block.active .category {
  background-color: var(--theme-color);
}

.pricing-block.active .category h4 {
  color: #fff;
}

.pricing-block.active .price {
  color: #fff;
}

.pricing-block ul li:before {
  position: relative;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: var(--theme-color);
  font-size: 12px;
  margin-right: 5px;
}

.pricing-block ul li.not-available:before {
  color: #737373;
  content: "\f00d";
}

/* Why choose section */

.why-choose-section {
  position: relative;
  padding: 120px 0 90px;
}

.why-choose-section:before {
  position: absolute;
  content: "";
  background-color: #f0f0f0;
  max-width: 1570px;
  width: 100%;
  top: -240px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  margin: 0 auto;
  border-radius: 30px;
}

.why-choose-section .list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.why-choose-section .list li {
  position: relative;
  width: 50%;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.why-choose-section .list li i {
  color: var(--theme-color);
  margin-right: 5px;
}

.why-choose-section .image-block {
  position: relative;
}

.why-choose-section .image {
  position: relative;
  padding-right: 20px;
  z-index: 1;
}

.why-choose-section .image:before {
  position: absolute;
  content: "";
  top: 20px;
  right: 0;
  bottom: 20px;
  left: 20px;
  background-color: var(--theme-color);
  border-radius: 30px;
}

.why-choose-section .image-block .shape {
  position: absolute;
  top: -85px;
  right: -80px;
}

/* Cta Section */

.cta-three {
  position: relative;
  padding: 80px 0 60px;
  background: var(--theme-color);
}

.cta-three h4 {
  position: relative;
  color: #fff;
  font-size: 36px;
  margin-bottom: 20px;
}

.cta-three .link-btn {
  margin-bottom: 20px;
}

/* Page Title */

.page-title {
  position: relative;
  padding: 0px 0 0px;
  text-align: center;
  background-size: cover;
}

.page-title h1 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 32px;
  line-height: 38.4px;
  color: #000000;
}
@media screen and (max-width: 600px) {
  .page-title h1 {
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
    color: #000000;
  }
}

/* .page-title h1:before {
  position: absolute;
  content: "";
  height: 3px;
  width: 76px;
  bottom: 0;
  left: 0;
  left: 50%;
  transform: translateX(-50%);
} */

.page-title .bread-crumb {
  position: relative;
}

.page-title .bread-crumb li {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  padding-right: 20px;
  font-size: 18px;
  color: var(--theme-color);
  font-weight: 400;
}

.page-title .bread-crumb li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.page-title .bread-crumb li:before {
  content: "/";
  position: absolute;
  right: 0;
  top: 2px;
  line-height: 25px;
  font-size: 17px;
  color: var(--theme-color);
  transform: rotate(180deg);
  font-weight: 400;
}

.page-title .bread-crumb li:last-child:before {
  display: none;
}

.page-title .bread-crumb li a {
  font-size: 18px;
  color: var(--theme-color);
  font-weight: 400;
}

/* Experience Section style two  */

section.experience-section.style-two .image {
  margin-top: 0;
  margin-bottom: 30px;
}

section.experience-section.style-two .image:before {
  position: absolute;
  content: "";
  left: -35px;
  bottom: 0;
  background: var(--theme-color);
  width: 50px;
  height: 285px;
  border-radius: 10px;
  z-index: 0;
}

section.experience-section.style-two .image img {
  position: relative;
  mix-blend-mode: normal;
}

section.experience-section.style-two {
  padding-bottom: 90px;
  border-bottom: 1px solid #ddd;
}

/* Team section two style two */
section.our-team-section.style-two {
  background: transparent;
}

/* Error Page */
section.error-section {
  position: relative;
  padding: 120px 0;
}

section.error-section .content {
  text-align: center;
}

section.error-section h1 {
  position: relative;
  font-size: 100px;
  font-weight: 600;
  line-height: 120px;
  margin-bottom: 10px;
}

section.error-section h2 {
  margin-bottom: 20px;
}

section.error-section .text {
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
}

/* Accordion box */

.accordion-box {
  position: relative;
  margin-bottom: 30px;
}

.accordion-box .block {
  position: relative;
  padding-top: 0px;
  margin-bottom: 30px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.accordion-box .block:last-child {
  margin-bottom: 0px;
}

.accordion-box .block .acc-btn {
  position: relative;
  cursor: pointer;
  line-height: 1.2em;
  transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  color: #181614;
  padding: 24px 20px;
  padding-left: 40px;
  border-radius: 10px;
  background: #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  font-size: 18px;
  color: #212121;
  font-weight: 500;
}

.accordion-box .block .icon-outer {
  position: absolute;
  right: 20px;
  top: 19px;
  font-size: 20px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  text-align: center;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #555;
}

.accordion-box .block.active-block .icon-outer span:before {
  content: "\f106";
}

.accordion-box .block .icon-outer .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 18px;
  font-weight: normal;
  line-height: 1em;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transform: translate(-50%, -50%);
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
  border-top: 0;
}

.accordion-box .block .acc-content .content-text {
  padding-bottom: 6px;
}

.accordion-box .block .acc-content.current {
  display: block;
}

.accordion-box .block .content {
  position: relative;
  font-size: 14px;
  padding: 5px 40px 21px;
}

.accordion-box .block .content .text {
  margin-bottom: 0;
  margin-top: 20px;
  font-size: 17px;
  line-height: 34px;
  color: #737373;
  font-weight: 400;
}

.accordion-box .block .acc-btn strong {
  font-size: 24px;
  color: #c2c0bb;
  width: 76px;
  text-align: center;
  border-right: 1px solid #e9e5df;
  margin-right: 30px;
  display: inline-block;
  line-height: 75px;
}

.accordion-box .block .acc-btn.active {
  background: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #d3d3d3;
  color: #212121;
}

.accordion-box .block.active-block {
  border-color: #d3d3d3;
}

.accordion-box .block .acc-btn.active .icon-outer {
  color: var(--theme-color);
}

/* services details */

.course-details-page {
  position: relative;
  padding: 120px 0 90px;
}

.service-widget {
  position: relative;
  padding: 45px 50px;
  border-radius: 10px;
  background-color: #f0f0f0;
  margin-bottom: 30px;
}

.service-widget h4.widget-title {
  position: relative;
  font-size: 20px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 20px;
}

.service-widget ul {
  position: relative;
}

.service-widget ul li {
  margin-bottom: 6px;
}

.service-widget ul li a {
  font-size: 17px;
  line-height: 26px;
  color: #737373;
  font-weight: 400;
  position: relative;
  padding: 11px 0;
  display: block;
  z-index: 1;
  transition: 0.5s;
}

.service-widget ul li a:before {
  position: absolute;
  content: "";
  top: 0;
  left: -20px;
  right: -20px;
  bottom: 0;
  background: #fff;
  border-radius: 24px;
  z-index: -1;
  transition: 0.5s;
  opacity: 0;
}

.service-widget ul li.current a:before {
  opacity: 1;
}

.service-widget ul li.current a {
  color: #212121;
  font-weight: 500;
  padding-left: 22px;
}

.service-widget ul li a:after {
  position: absolute;
  content: "\f30b";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: var(--theme-color);
  font-size: 17px;
  left: 0;
  top: 12px;
  opacity: 0;
}

.service-widget ul li.current a:after {
  opacity: 1;
}

.contact-widget-two {
  position: relative;
  margin-bottom: 30px;
}

.contact-widget-two img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.contact-widget-two .content {
  background: var(--theme-color);
  text-align: center;
  padding-bottom: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.contact-widget-two .icon {
  width: 62px;
  height: 62px;
  border-radius: 31px;
  background-color: #ffffff;
  margin: 0 auto;
  line-height: 70px;
  font-size: 25px;
  color: var(--theme-color);
  margin-top: -31px;
  z-index: 9;
  position: relative;
  margin-bottom: 40px;
}

.contact-widget-two .icon:before {
  position: absolute;
  content: "";
  top: -19.5px;
  left: -19.5px;
  width: 101px;
  height: 101px;
  border-radius: 51px;
  background-color: rgba(255, 255, 255, 0.2);
}

.contact-widget-two h5 {
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 500;
}

.contact-widget-two .phone-number {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 700;
}

.contact-widget-two .phone-number a {
  color: #fff;
}

.course-details {
  position: relative;
}

.course-details .image {
  position: relative;
  margin-bottom: 30px;
}

.course-details h2 {
  font-size: 36px;
  line-height: 36px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 35px;
}

.course-details .text {
  font-size: 18px;
  line-height: 36px;
  color: #737373;
  font-weight: 400;
  margin-bottom: 30px;
}

.course-details .text-block {
  margin-bottom: 30px;
}

.course-details .text-block h4 {
  position: relative;
  font-size: 18px;
  line-height: 30px;
  color: #222222;
  font-weight: 500;
  margin-bottom: 6px;
}

.course-details .text-block h4 span {
  color: var(--theme-color);
  margin-right: 5px;
}

.course-details .text-block .text-two {
  font-size: 16px;
  line-height: 30px;
  color: #737373;
  font-weight: 400;
}

.course-details .text-block-two h4 {
  position: relative;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 34px;
  color: #212121;
  font-weight: 500;
}

.course-details .text-block-two .text-three {
  font-size: 20px;
  line-height: 34px;
  color: var(--theme-color);
  font-weight: 400;
  font-style: italic;
  margin-bottom: 25px;
}

.course-details .text-block-two ul {
  margin-bottom: 30px;
}

.course-details .text-block-two ul li {
  font-size: 17px;
  line-height: 30px;
  color: #212121;
  font-weight: 400;
  margin-bottom: 10px;
}

.course-details .text-block-two ul li i {
  font-size: 16px;
  margin-right: 10px;
  color: var(--theme-color);
}

/* Faq section */

.faq-section {
  position: relative;
  padding: 10px 0 10px;
}

/* Sidebar Page container */

.sidebar-page-container {
  position: relative;
  padding: 120px 0 90px;
}

.news-block-three {
  position: relative;
}

.news-block-three .inner-box {
  position: relative;
  margin-bottom: 30px;
}

.news-block-three .image {
  position: relative;
  overflow: hidden;
}

.news-block-three .image img {
  position: relative;
  width: 100%;
  transition: 0.5s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.news-block-three .date {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 20px;
}

.news-block-three .content {
  position: relative;
  background-color: #fff;
  padding: 30px 40px 30px;
  border: 1px solid #dcdcdc;
}

.news-block-three .post-meta {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.news-block-three .post-meta li {
  position: relative;
  margin-right: 10px;
  transition: 0.5s;
  font-size: 15px;
  line-height: 40px;
  color: #737373;
  font-weight: 400;
}

.news-block-three .post-meta li i {
  margin-right: 3px;
  color: var(--theme-color);
}

.news-block-three h4 {
  position: relative;
  margin-bottom: 20px;
  font-size: 26px;
  line-height: 40px;
  color: #212121;
  font-weight: 500;
}

.news-block-three h4 a {
  color: #181614;
  transition: 0.5s;
}

.news-block-three .text {
  position: relative;
  margin-bottom: 0;
  font-size: 17px;
  line-height: 34px;
  color: #737373;
  font-weight: 400;
}

/*** 

====================================================================
    Styled Pagination
====================================================================

***/

.styled-pagination {
  position: relative;
  margin-bottom: 30px;
}

.styled-pagination li {
  position: relative;
  display: inline-block;
  margin: 0px 6px 0px 0px;
}

.styled-pagination li a {
  position: relative;
  display: inline-block;
  line-height: 78px;
  width: 78px;
  height: 78px;
  font-size: 18px;
  color: #181614;
  font-weight: 700;
  text-align: center;
  background: #f9f8f7;
  text-transform: capitalize;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
}

.styled-pagination li a:hover,
.styled-pagination li a.active {
  color: #ffffff;
}

.styled-pagination li.prev a:hover,
.styled-pagination li.next a:hover {
  background-color: #181614;
}

/*Search Box Widget*/

.sidebar .search-box .form-group {
  position: relative;
  margin: 0px;
}

.blog-sidebar .widget.search-box {
  padding: 0;
  background: transparent;
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"] {
  position: relative;
  line-height: 30px;
  padding: 10px 50px 10px 50px;
  display: block;
  font-size: 16px;
  width: 100%;
  height: 75px;
  color: #fff;
  font-weight: 400;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  background: var(--theme-color);
  border-radius: 10px;
}

.sidebar .search-box .form-group button {
  position: absolute;
  right: 10px;
  top: 0px;
  height: 75px;
  width: 70px;
  display: block;
  font-size: 18px;
  line-height: 100%;
  font-weight: normal;
  background: none;
  color: #fff;
}

/*Blog Category*/

.category-widget .cat-list {
  position: relative;
}

.category-widget .cat-list li {
  position: relative;
}

.category-widget .cat-list li a {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: block;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  font-size: 18px;
  line-height: 26px;
  color: #737373;
  font-weight: 400;
}

.category-widget .cat-list li a:hover {
  color: var(--theme-color);
}

.category-widget .cat-list li:last-child a {
  border-bottom: 0px;
  padding-bottom: 0;
  margin-bottom: 0;
}

/*news Widget*/

.news-widget-two .post {
  position: relative;
  font-size: 14px;
  color: #666666;
  padding: 0 0 18px;
  padding-left: 90px;
  min-height: 90px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 30px;
}

.news-widget-two .post:last-child {
  margin-bottom: 0px;
  border-bottom: 0;
  padding-bottom: 0;
}

.news-widget-two .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 4px;
  width: 70px;
}

.news-widget-two .post .post-thumb img {
  display: block;
  width: 100%;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-widget-two .post .text {
  position: relative;
  margin: 0px 0px 0px;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 30px;
  color: #212121;
  font-weight: 500;
}

.news-widget-two .post .text a {
  color: #2d2727;
  display: inline-block;
  line-height: 26px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-widget-two .comment {
  font-size: 15px;
  line-height: 26px;
  color: #737373;
  font-weight: 400;
}

.news-widget-two .comment i {
  margin-right: 5px;
  color: var(--theme-color);
}

/*Popular Tags*/

.tag-widget a {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 8px 33px;
  min-width: 100px;
  margin: 0px 6px 10px 0px;
  color: #706767;
  text-align: center;
  font-size: 12px;
  background: none;
  font-weight: 700;
  background: #fff;
  text-transform: uppercase;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  font-size: 12px;
  letter-spacing: 1px;
  color: #212121;
  font-weight: 700;
  border-radius: 20px;
}

.tag-widget a:hover {
  color: #fff;
  background: var(--theme-color);
}

.blog-sidebar .widget {
  background: #f0f0f0;
  padding: 50px;
  padding-right: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.blog-sidebar .widget .widget_title {
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  font-size: 20px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 25px;
}

/* author box */

.sidebar-page-container .author-box {
  position: relative;
  padding: 60px 60px 40px;
  padding-left: 260px;
  margin-bottom: 75px;
  background: #f0f0f0;
  min-height: 282px;
  border-radius: 10px;
}

.sidebar-page-container .author-box .image {
  position: absolute;
  left: 60px;
  top: 60px;
  width: 170px;
}

.sidebar-page-container .author-box .image img {
}

.sidebar-page-container .author-box h4 {
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 34px;
  color: #212121;
  font-weight: 500;
}

.sidebar-page-container .author-box h5 {
  font-family: inherit;
  margin-bottom: 10px;
}

.sidebar-page-container .author-box h5 a {
  position: relative;
  font-size: 18px;
  font-weight: 500;
}

.sidebar-page-container .author-box .text {
  position: relative;
  margin-bottom: 20px;
  font-size: 17px;
  line-height: 34px;
  color: #737373;
  font-weight: 400;
}

/* Comments area */
.comments-area {
  position: relative;
  margin-top: 30px;
  margin-bottom: 70px;
}

.sidebar-page-container .group-title {
  position: relative;
}

.sidebar-page-container .group-title h3 {
  position: relative;
  margin-bottom: 35px;
  font-size: 30px;
  color: #212121;
  font-weight: 500;
}

.comments-area .comment-box {
  position: relative;
  margin-bottom: 40px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.comments-area .comment-box.reply-comment {
  margin-left: 65px;
}

.comments-area .comment {
  position: relative;
  font-size: 14px;
  padding: 0 0px 30px 170px;
  border-bottom: 1px solid #eae1da;
}

.comments-area .comment .comment-inner {
  position: relative;
}

.comments-area .comment-box.reply-comment .comment-inner {
  background-color: #fff;
}

.comments-area .comment .comment-inner .text {
  position: relative;
  font-size: 18px;
  line-height: 1.5em;
  margin-bottom: 15px;
  font-size: 17px;
  line-height: 34px;
  color: #737373;
  font-weight: 400;
}

.comments-area .comment .comment-inner h5 {
  font-size: 20px;
  line-height: 34px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 15px;
}

.comments-area .comment-box .author-thumb {
  position: absolute;
  left: 0px;
  top: 14px;
  overflow: hidden;
  margin-bottom: 20px;
}

.comments-area .comment-box .author-thumb img {
  width: 142px;
  display: block;
}

.comments-area .comment-info {
  position: relative;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 34px;
  color: #212121;
  font-weight: 500;
}

.comments-area .comment-info a {
  position: relative;
  color: #1e1e29;
}

.comments-area .date {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  background: transparent !important;
  padding: 0;
  position: relative;
  margin-left: 5px;
}

.comments-area .comment-box .reply-comment-btn {
  position: absolute;
  right: 0;
  top: -10px;
  color: #fff;
  padding: 6px 27px;
  font-size: 12px;
  font-weight: 700;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  border-radius: 19px;
  background-color: var(--theme-color);
  text-transform: uppercase;
}

.comments-area .comment-box .reply-comment-btn:hover {
  background-color: #333;
}

/* Comment Form */

.comment-form {
  position: relative;
  margin-bottom: 30px;
}

.comment-form .form-group {
  position: relative;
  margin-bottom: 30px;
}

.comment-form .form-group:last-child {
  margin-bottom: 0px;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group textarea,
.comment-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 28px;
  padding: 15px 25px;
  height: 58px;
  color: #7e7b7a;
  font-size: 18px;
  background: #f0f0f0;
  border: 1px solid transparent;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.comment-form .form-group textarea {
  height: 180px;
}

.comment-form button {
  position: relative;
  display: block;
  margin-top: 10px;
}

.comment-form .note {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
}

.comment-form .note input {
  margin-right: 7px;
}

.sidebar-page-container .comment-form .text {
  margin-bottom: 40px;
}

.sidebar-page-container .comment-form .row-10 {
  margin: 0 -10px;
}

.sidebar-page-container .comment-form .form-group {
  padding: 0 10px;
  margin-bottom: 20px;
}

.post-tag {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dcdcdc;
  border-top: 0;
  padding: 0 40px;
  padding-top: 20px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.post-tag ul.tag {
  display: inline-block;
}

.post-tag ul.tag li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.post-tag span.tag-title {
  margin-right: 10px;
  font-size: 20px;
  color: #212121;
  font-weight: 500;
}

.post-tag ul.tag li a {
  padding: 11px 20px;
  transition: 0.5s;
  border-radius: 19px;
  background-color: var(--theme-color);
  font-size: 12px;
  letter-spacing: 1px;
  color: #fefefe;
  font-weight: 700;
  transition: 0.5s;
}

.post-tag ul.tag li a:hover {
  background-color: #222;
}

.post-tag .social-icon {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.post-tag .social-icon li {
  position: relative;
  margin: 0 5px 10px;
}

.post-tag .social-icon li a {
  color: #181614;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  line-height: 52px;
  background: #f9f8f7;
  transition: 0.5s;
  width: 52px;
  height: 52px;
  border-radius: 26px;
  background-color: #f0f0f0;
}

.post-tag .social-icon li a:hover {
  color: #fff;
  background: var(--theme-color);
}

/* Map section */

section.map-section {
  position: relative;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
  padding: auto;
}
.address_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.contactus-btn-style-one {
  position: relative;
  line-height: 24px;
  overflow: hidden;
  width: 99%;
  height: 35px;
  gap: 8px;
  background-color: #fb561e;
  font-size: 14px;
  color: #fefefe;
  font-weight: 700;
  transition: 0.5s;
  z-index: 0;
}
@media screen and (max-width: 600px) {
  section.map-section {
    position: relative;
    padding: 10px 0;
  }
}

section.map-section .auto-container {
  max-width: 1600px;
}

/* Contact info section */
section.contact-info-section {
  position: relative;
  padding: 120px 0;
  background: #f0f0f0;
}

section.contact-info-section:before {
  position: absolute;
  content: "";
  top: -295px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f0f0f0;
  z-index: -1;
}

section.contact-info-section .icon_box {
  position: relative;
}

section.contact-info-section .icon_box .inner-box {
  position: relative;
  background: #fff;
  padding: 40px 40px 30px;
  border-radius: 10px;
  padding-left: 105px;
  min-height: 147px;
  margin-bottom: 30px;
}

section.contact-info-section .icon_box .icon {
  width: 49px;
  height: 49px;
  border-radius: 25px;
  background-color: var(--theme-color);
  text-align: center;
  line-height: 54px;
  font-size: 20px;
  color: #fff;
  position: absolute;
  left: 40px;
}

section.contact-info-section .icon_box .text {
  font-size: 14px;
  line-height: 26px;
  color: #737373;
  font-weight: 400;
  margin-bottom: 0;
}

section.contact-info-section .icon_box h4 {
  font-size: 18px;
  line-height: 25px;
  color: #212121;
  font-weight: 400;
}

section.contact-info-section .icon_box h4 a {
  color: #212121;
  transition: 0.5s;
}

section.contact-info-section .icon_box h4 a:hover {
  color: var(--theme-color);
}

section.contact-info-section h3 {
  position: relative;
  font-size: 20px;
  line-height: 44px;
  color: #737373;
  font-weight: 400;
  margin-bottom: 30px;
}

section.contact-info-section .bottom-content {
  margin-top: 30px;
}

section.contact-info-section h3 span {
  color: #212121;
}

section.contact-info-section ul.social-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

section.contact-info-section ul.social-links li {
  margin-right: 10px;
  margin-bottom: 10px;
}

section.contact-info-section ul.social-links a {
  width: 52px;
  height: 52px;
  border-radius: 26px;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 52px;
  transition: 0.5s;
  font-size: 15px;
  color: #737373;
}

section.contact-info-section ul.social-links a:hover {
  background: var(--theme-color);
  color: #fff;
}

.cta-three a.btn-style-two:hover:before {
  background: #fff;
}

.cta-three a.btn-style-two:hover {
  color: var(--theme-color);
}

.news-block-one.style-two ul.post-mate {
  background: transparent;
  padding: 0;
}

.news-block-one .read-more {
  position: absolute;
  top: -23px;
  right: 30px;
}

.news-block-one .read-more a {
  position: relative;
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background-color: #fb7500;
  display: inline-block;
  text-align: center;
  color: #fff;
  line-height: 46px;
}

/* Post Pagination */

.post-pagination {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.post-pagination li {
  display: inline-block;
  margin: 0 4px 10px;
}

.post-pagination li a {
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: block;
  background-color: #f0f4f9;
  text-align: center;
  color: #222;
  font-size: 16px;
  font-weight: 500;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  border-radius: 5px;
}

.post-pagination li span {
  font-size: 12px;
}

.post-pagination li.active a,
.post-pagination li a:hover {
  color: #fff;
  border-color: transparent;
  background-color: var(--theme-color);
}
